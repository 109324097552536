import styled, { css } from 'styled-components';
import theme from '../../../../../theme/theme';

export const FooterStyled = styled.footer`
  width: 100%;
  height: 40px;
  background: ${theme.colors.abbvie};
  bottom: 0;
  display: flex;
  align-items: center;
`

export const FooterElements = styled.div`
  display: flex;
  gap: 4px;
  height: 100%;
`

const footerElement = css`
  color: #FFF;
  font-size: 10px;
  font-weight: 300;
  line-height: 120%;

`
export const FooterElement = styled.span`
  ${footerElement};
  text-decoration-line: underline;

  a {
    color: white;
  }

`

export const Separator = styled.span`
  ${footerElement};

`

export const Container = styled.div`
  width: 1080px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  position: relative;
`