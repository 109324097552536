import { observer } from "mobx-react";
import { FormikErrors } from "formik";

// hooks
import { useStage04ViewModel } from "./Stage04.vm";

// components
import { Divider, Grid, Typography } from "@mui/material";
import { Label } from "../../../../../../../../shared/ui/label/Label";
import { Input } from "../../../../../../../../shared/ui/input/Input";
import { SingleSelect } from "../../../../../../../../shared/ui/singleSelect/SingleSelect";
import { RadioBox } from "../../../../../../../../shared/ui/radioBox/RadioBox";
import { DateInput } from "../../../../../../../../shared/ui/date/Date";

// lib
import theme from "../../../../../../../../../theme/theme";

//icons
import { ReactComponent as Bin } from "../../../../../../../../../assets/icon/abbvieBin.svg";
import { ReactComponent as Plus } from "../../../../../../../../../assets/icon/abbviePlus.svg";

//types, helpers, styles
import { ICombinations } from "../stage03/type";
import { combinationsOptions } from "./Stage04.helper";
import { ButtonAddDrug } from "../stage02/Stage02.styled";
import { likertOptions, scaleOptions } from "../stage02/Stage02.helper";
import { IObservations } from "./type";
import { RadioForm } from "../../../styles";

export const Stage04 = observer(() => {
  const {
    formik,
    lzsCharacter,
    setOption,
    modifyObservations,
    addCombination,
    deleteCombination,
    setCombinationOption,
    addOtherCombination,
    resetForDas,
    resetForLikert,
    resetForLikertTreatments,
    resetForDasTreatments,
    handleYes,
    handleNo,
    addObservation,
    deleteMode,
    deleteObservation,
  } = useStage04ViewModel();

  return (
    <Grid
      container
      maxWidth={"714px"}
      display={"flex"}
      margin={"0 auto"}
      my={4}
      component="form"
    >
      <Grid item mb={5} display={"flex"} flexDirection={"column"}>
        <Label
          size={"small"}
          isUpperCase={true}
          color={theme.colors.grey1}
          label={"Czy lek Skyrizi był używany w pierwszej linii leczenia?"}
          isRequired={true}
        />
        <RadioForm>
          <RadioBox
            isError={formik.touched.wasUsed && Boolean(formik.errors.wasUsed)}
            id={"wasUsed"}
            name={"wasUsed"}
            value={"tak"}
            checked={formik.values.wasUsed === "tak"}
            onChange={(e) => {
              handleYes();
              formik.handleChange(e);
            }}
          />
          <RadioBox
            isError={formik.touched.wasUsed && Boolean(formik.errors.wasUsed)}
            id={"wasUsed2"}
            name={"wasUsed"}
            value={"nie"}
            checked={formik.values.wasUsed === "nie"}
            onChange={(e) => {
              handleNo();
              formik.handleChange(e);
            }}
          />
        </RadioForm>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography
          variant={"h6"}
          color={theme.colors.grey1}
          textTransform={"uppercase"}
          fontWeight={700}
        >
          Wyniki Badań przed włączeniem Skyrizi{" "}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <DateInput
          disableFuture={true}
          openTo={"year"}
          inputFormat={"yyyy/MM/dd"}
          views={["year", "month", "day"]}
          isRequired={true}
          maxWidth={"254px"}
          label={<>Data włączenia leku Skyrizi</>}
          isUpperCase={true}
          name={"drugInitiationDate"}
          size={"small"}
          color={theme.colors.grey1}
          value={formik.values.drugInitiationDate}
          onClose={() => {
            formik.setFieldTouched("drugInitiationDate", true);
            formik.validateField("drugInitiationDate");
          }}
          onChange={(e, context) => {
            formik.setFieldValue("drugInitiationDate", e);
          }}
          onBlur={() => {
            formik.setFieldTouched("drugInitiationDate", true);
            setTimeout(() => {
              formik.validateField("drugInitiationDate");
            }, 0);
          }}
          isError={
            formik.touched.drugInitiationDate &&
            Boolean(formik.errors.drugInitiationDate)
          }
        />
      </Grid>
      {lzsCharacter === "postać obwodowa" && (
        <>
          <Grid
            xs={12}
            item
            pb={2}
            display={"flex"}
            flexDirection={"column"}
            mt={2}
          >
            <Label
              size={"small"}
              isUpperCase={false}
              color={theme.colors.grey1}
              label={"KRYTERIA PsARC"}
            />
            <RadioForm>
              <RadioBox
                isError={
                  formik.touched.criteriaPsarc4 &&
                  Boolean(formik.errors.criteriaPsarc4)
                }
                id={"criteriaPsarc4"}
                name={"criteriaPsarc4"}
                value={"das 28"}
                checked={formik.values.criteriaPsarc4 === "das 28"}
                onChange={(e) => {
                  formik.handleChange(e);
                  resetForDas();
                }}
              />
              <RadioBox
                isError={
                  formik.touched.criteriaPsarc4 &&
                  Boolean(formik.errors.criteriaPsarc4)
                }
                id={"criteriaPsarc42"}
                name={"criteriaPsarc4"}
                value={"likert"}
                checked={formik.values.criteriaPsarc4 === "likert"}
                onChange={(e) => {
                  formik.handleChange(e);
                  resetForLikert();
                }}
              />
            </RadioForm>
          </Grid>
          {formik.values.criteriaPsarc4 === "das 28" && (
            <>
              <Grid container display={"flex"} gap={"20px"} mb={2} mt={2}>
                <Grid item pb={2} display={"flex"} flexDirection={"column"}>
                  <Label
                    size={"small"}
                    isUpperCase={true}
                    color={theme.colors.grey1}
                    label={"Wynik das 28"}
                    isRequired={true}
                  />
                  <RadioForm>
                    <RadioBox
                      isError={
                        formik.touched.das28Result4 &&
                        Boolean(formik.errors.das28Result4)
                      }
                      id={"das28Result4"}
                      name={"das28Result4"}
                      value={"crp"}
                      checked={formik.values.das28Result4 === "crp"}
                      onChange={formik.handleChange}
                    />
                    <RadioBox
                      isError={
                        formik.touched.das28Result4 &&
                        Boolean(formik.errors.das28Result4)
                      }
                      id={"das28Result42"}
                      name={"das28Result4"}
                      value={"ob"}
                      checked={formik.values.das28Result4 === "ob"}
                      onChange={formik.handleChange}
                    />
                  </RadioForm>
                </Grid>
                <Grid>
                  <Input
                    isRequired={true}
                    label={"Wynik"}
                    isUpperCase={true}
                    name={"result4"}
                    type={"number"}
                    maxWidth={"163px"}
                    onChangeCustom={() => formik.setFieldValue("result4", null)}
                    colorLabel={theme.colors.grey1}
                    value={formik.values.result4}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    borderColor={theme.colors.grey5}
                    placeholderColor={theme.colors.grey4}
                    background={theme.colors.white}
                    placeholder={"Wpisz"}
                    isError={
                      formik.touched.result4 && Boolean(formik.errors.result4)
                    }
                  />
                </Grid>
                <Grid
                  item
                  justifyContent={"space-between"}
                  display={"flex"}
                  flexDirection={"column"}
                >
                  <Label
                    size={"small"}
                    isUpperCase={true}
                    color={theme.colors.grey1}
                    label={"Wynik skali"}
                  />
                  <Typography variant="caption" color={theme.colors.grey1}>
                    &lt;2,6 - remisja <br />
                    &le;3,2 – aktywność mała <br />
                    &gt;3,2 i &le;5,1 – aktywność umiarkowana <br />
                    &gt;5,1 – aktywność duża
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
          {formik.values.criteriaPsarc4 === "likert" && (
            <>
              <Grid container display={"flex"} gap={"20px"} mt={2}>
                <Grid item xs={5}>
                  <SingleSelect
                    isRequired={true}
                    defaultValue={"Wybierz"}
                    isError={
                      formik.touched.likertPatient4 &&
                      Boolean(formik.errors.likertPatient4)
                    }
                    label={"Wynik w skali Likerta pacjent"}
                    handleChange={(value) => setOption(value, "likertPatient4")}
                    selectedValue={formik.values.likertPatient4 ?? ""}
                    elementName={"likertPatient4"}
                    options={likertOptions}
                  />
                </Grid>
                <Grid>
                  <SingleSelect
                    isRequired={true}
                    defaultValue={"Wybierz"}
                    isError={
                      formik.touched.likertDoctor4 &&
                      Boolean(formik.errors.likertDoctor4)
                    }
                    label={"Wynik w skali Likerta lekarz"}
                    handleChange={(value) => setOption(value, "likertDoctor4")}
                    selectedValue={formik.values.likertDoctor4 ?? ""}
                    elementName={"likertDoctor4"}
                    options={likertOptions}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={5}>
                  <SingleSelect
                    isRequired={true}
                    defaultValue={"Wybierz"}
                    isError={
                      formik.touched.generalAssessment4 &&
                      Boolean(formik.errors.generalAssessment4)
                    }
                    label={"OGÓLNA OCENA STANU CHOROBY"}
                    handleChange={(value) =>
                      setOption(value, "generalAssessment4")
                    }
                    selectedValue={formik.values.generalAssessment4 ?? ""}
                    elementName={"generalAssessment4"}
                    options={scaleOptions}
                  />
                </Grid>
              </Grid>
              <Grid container display={"flex"} gap={"20px"}>
                <Grid item xs={5}>
                  <Input
                    label={"Liczba bolesnych stawów"}
                    isUpperCase={true}
                    name={"tenderJointsCount4"}
                    type={"number"}
                    onChangeCustom={() =>
                      formik.setFieldValue("tenderJointsCount4", null)
                    }
                    maxWidth={"163px"}
                    colorLabel={theme.colors.grey1}
                    value={formik.values.tenderJointsCount4}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    borderColor={theme.colors.grey5}
                    placeholderColor={theme.colors.grey4}
                    background={theme.colors.white}
                    placeholder={"Wpisz"}
                    isError={
                      formik.touched.tenderJointsCount4 &&
                      Boolean(formik.errors.tenderJointsCount4)
                    }
                  />
                </Grid>
                <Grid>
                  <Input
                    label={"Liczba obrzękniętych stawów"}
                    isUpperCase={true}
                    name={"swollenJointsCount4"}
                    type={"number"}
                    onChangeCustom={() =>
                      formik.setFieldValue("swollenJointsCount4", null)
                    }
                    maxWidth={"163px"}
                    colorLabel={theme.colors.grey1}
                    value={formik.values.swollenJointsCount4}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    borderColor={theme.colors.grey5}
                    placeholderColor={theme.colors.grey4}
                    background={theme.colors.white}
                    placeholder={"Wpisz"}
                    isError={
                      formik.touched.swollenJointsCount4 &&
                      Boolean(formik.errors.swollenJointsCount4)
                    }
                  />
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
      {lzsCharacter === "postać osiowa" && (
        <>
          <Grid container display={"flex"} gap={"20px"} mb={2} mt={2}>
            <Grid item pb={2} display={"flex"} flexDirection={"column"}>
              <Label
                size={"small"}
                isUpperCase={true}
                color={theme.colors.grey1}
                label={"Podaj wynik w skali"}
                isRequired={true}
              />
              <RadioForm>
                <RadioBox
                  isError={
                    formik.touched.resultScaleOption4 &&
                    Boolean(formik.errors.resultScaleOption4)
                  }
                  id={"resultScaleOption4"}
                  name={"resultScaleOption4"}
                  value={"BASDAI"}
                  checked={formik.values.resultScaleOption4 === "BASDAI"}
                  onChange={formik.handleChange}
                />
                <RadioBox
                  isError={
                    formik.touched.resultScaleOption4 &&
                    Boolean(formik.errors.resultScaleOption4)
                  }
                  id={"resultScaleOption42"}
                  name={"resultScaleOption4"}
                  value={"ASDAS"}
                  checked={formik.values.resultScaleOption4 === "ASDAS"}
                  onChange={formik.handleChange}
                />
              </RadioForm>
            </Grid>
            <Grid>
              <Input
                isRequired={true}
                label={"Wynik"}
                isUpperCase={true}
                name={"resultScale4"}
                type={"number"}
                onChangeCustom={() =>
                  formik.setFieldValue("resultScale4", null)
                }
                maxWidth={"163px"}
                colorLabel={theme.colors.grey1}
                value={formik.values.resultScale4}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                borderColor={theme.colors.grey5}
                placeholderColor={theme.colors.grey4}
                background={theme.colors.white}
                placeholder={"Wpisz"}
                isError={
                  formik.touched.resultScale4 &&
                  Boolean(formik.errors.resultScale4)
                }
              />
            </Grid>
          </Grid>
          <Grid container display={"flex"} gap={"20px"}>
            <Grid item xs={5}>
              <SingleSelect
                isRequired={true}
                defaultValue={"Wybierz"}
                isError={
                  formik.touched.degreeOfBack4 &&
                  Boolean(formik.errors.degreeOfBack4)
                }
                label={"Stopień bólu kręgosłupa"}
                handleChange={(value) => setOption(value, "degreeOfBack4")}
                selectedValue={formik.values.degreeOfBack4 ?? ""}
                elementName={"degreeOfBack4"}
                options={scaleOptions}
              />
            </Grid>
            <Grid>
              <SingleSelect
                isRequired={true}
                defaultValue={"Wybierz"}
                isError={
                  formik.touched.generalAssessment24 &&
                  Boolean(formik.errors.generalAssessment24)
                }
                label={"OGÓLNA OCENA STANU CHOROBY"}
                handleChange={(value) =>
                  setOption(value, "generalAssessment24")
                }
                selectedValue={formik.values.generalAssessment24 ?? ""}
                elementName={"generalAssessment24"}
                options={scaleOptions}
              />
            </Grid>
          </Grid>
        </>
      )}
      <Grid container mt={2} gap={"20px"}>
        <Grid item xs={3}>
          <Input
            label={"wynik w skali pasi"}
            isUpperCase={true}
            name={"pasi4"}
            type={"number"}
            onChangeCustom={() => formik.setFieldValue("pasi4", null)}
            maxWidth={"163px"}
            colorLabel={theme.colors.grey1}
            value={formik.values.pasi4}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz"}
            isError={formik.touched.pasi4 && Boolean(formik.errors.pasi4)}
          />
        </Grid>
        <Grid item xs={3}>
          <Input
            label={"wynik w skali DLQI"}
            isUpperCase={true}
            name={"dlqi4"}
            type={"number"}
            onChangeCustom={() => formik.setFieldValue("dlqi4", null)}
            maxWidth={"163px"}
            colorLabel={theme.colors.grey1}
            value={formik.values.dlqi4}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz"}
            isError={formik.touched.dlqi4 && Boolean(formik.errors.dlqi4)}
          />
        </Grid>
        <Grid item xs={3}>
          <Input
            label={"wynik w skali BSA"}
            isUpperCase={true}
            name={"bsa4"}
            type={"number"}
            onChangeCustom={() => formik.setFieldValue("bsa4", null)}
            maxWidth={"163px"}
            colorLabel={theme.colors.grey1}
            value={formik.values.bsa4}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz"}
            isError={formik.touched.bsa4 && Boolean(formik.errors.bsa4)}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} mb={2} mt={3}>
        <Typography
          variant={"h6"}
          color={theme.colors.grey1}
          textTransform={"uppercase"}
          fontWeight={700}
        >
          Obserwacja na leku Skyrizi
        </Typography>
      </Grid>
      <Grid container display={"flex"} gap={"20px"} mb={2}>
        <Grid item pb={2} display={"flex"} flexDirection={"column"}>
          <Label
            size={"small"}
            isUpperCase={true}
            color={theme.colors.grey1}
            label={"Interwały obserwacji"}
            isRequired={true}
          />
          <RadioForm>
            <RadioBox
              isError={
                formik.touched.observationIntervals &&
                Boolean(formik.errors.observationIntervals)
              }
              id={"observationIntervals"}
              name={"observationIntervals"}
              value={"co 1 msc."}
              checked={formik.values.observationIntervals === "co 1 msc."}
              onChange={(e) => {
                formik.handleChange(e);
                modifyObservations(e.target.value);
              }}
            />
            <RadioBox
              isError={
                formik.touched.observationIntervals &&
                Boolean(formik.errors.observationIntervals)
              }
              id={"observationIntervals2"}
              name={"observationIntervals"}
              value={"co 3 msc."}
              checked={formik.values.observationIntervals === "co 3 msc."}
              onChange={(e) => {
                formik.handleChange(e);
                modifyObservations(e.target.value);
              }}
            />
            <RadioBox
              isError={
                formik.touched.observationIntervals &&
                Boolean(formik.errors.observationIntervals)
              }
              id={"observationIntervals3"}
              name={"observationIntervals"}
              value={"co 6 msc."}
              checked={formik.values.observationIntervals === "co 6 msc."}
              onChange={(e) => {
                formik.handleChange(e);
                modifyObservations(e.target.value);
              }}
            />
          </RadioForm>
        </Grid>
      </Grid>

      {formik.values.observations?.length > 0 && (
        <>
          <Divider
            style={{ width: "100%" }}
            variant={"fullWidth"}
            sx={{ height: "2px", margin: "0 0 24px 0" }}
          />
          <Grid
            item
            xs={12}
            mb={2}
            mt={2}
            display={"flex"}
            justifyContent={"center"}
          >
            <Typography
              variant={"h6"}
              color={theme.colors.grey1}
              textTransform={"uppercase"}
              fontWeight={700}
            >
              Obserwacje
            </Typography>
          </Grid>
        </>
      )}
      <Grid container>
        {formik.values.observations?.map((line, index) => (
          <Grid
            key={index}
            item
            xs={12}
            mb={4}
            style={{
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              padding: "0 16px",
              backgroundColor: "rgba(255, 255, 255, 0.70)",
            }}
          >
            <Grid
              item
              xs={12}
              mt={2}
              mb={4}
              justifyContent={"space-between"}
              display={"flex"}
            >
              <Typography
                variant={"h6"}
                color={theme.colors.grey1}
                textTransform={"uppercase"}
                fontWeight={700}
              >
                Obserwacja {index + 1}
              </Typography>
              {deleteMode && (
                <ButtonAddDrug
                  type="button"
                  onClick={() => deleteObservation(index)}
                >
                  Usuń obserwację
                  <Bin />
                </ButtonAddDrug>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent={"space-between"}
              pb={2}
              display={"flex"}
              flexDirection={"column"}
            >
              <Label
                size={"small"}
                isUpperCase={true}
                color={theme.colors.grey1}
                label={"Monoterapia czy kombinacja z innym lekiem?"}
                isRequired={true}
              />
              <RadioForm>
                <RadioBox
                  isError={
                    Array.isArray(formik.touched.observations) &&
                    formik.touched.observations[index]?.monotherapy &&
                    Array.isArray(formik.errors.observations) &&
                    Boolean(
                      (
                        formik.errors.observations[
                          index
                        ] as FormikErrors<IObservations>
                      )?.monotherapy
                    )
                  }
                  id={`observations.monotherapy1-${index}`}
                  name={`observations[${index}].monotherapy`}
                  value={"monoterapia"}
                  checked={
                    formik.values.observations[index].monotherapy ===
                    "monoterapia"
                  }
                  onChange={(e) => {
                    formik.setFieldValue(
                      `observations[${index}].monotherapy`,
                      e.target.value
                    );
                    formik.setFieldValue(
                      `observations[${index}].combinationWithMedicine`,
                      []
                    );
                  }}
                />
                <RadioBox
                  isError={
                    Array.isArray(formik.touched.observations) &&
                    formik.touched.observations[index]?.monotherapy &&
                    Array.isArray(formik.errors.observations) &&
                    Boolean(
                      (
                        formik.errors.observations[
                          index
                        ] as FormikErrors<IObservations>
                      )?.monotherapy
                    )
                  }
                  id={`observations.monotherapy2-${index}`}
                  name={`observations[${index}].monotherapy`}
                  value={"kombinacja"}
                  checked={
                    formik.values.observations[index].monotherapy ===
                    "kombinacja"
                  }
                  onChange={(e) => {
                    formik.setFieldValue(
                      `observations[${index}].monotherapy`,
                      e.target.value
                    );
                    addCombination(index);
                  }}
                />
              </RadioForm>
            </Grid>
            {formik.values.observations &&
              formik.values.observations.length > 0 &&
              formik.values.observations[index]?.combinationWithMedicine
                .length > 0 &&
              formik.values.observations[index]?.combinationWithMedicine?.map(
                (combination, idx) => (
                  <Grid
                    style={{ backgroundColor: "rgba(7, 28, 71, 0.10)" }}
                    key={`observation.combination-${index}${idx}`}
                  >
                    <Grid key={idx} item xs={12} p={2} mb={2}>
                      <Grid
                        item
                        xs={12}
                        mt={2}
                        justifyContent={"space-between"}
                        display={"flex"}
                      >
                        <Typography
                          variant={"h6"}
                          color={theme.colors.grey1}
                          textTransform={"uppercase"}
                          fontWeight={700}
                        >
                          Kombinacja {idx + 1}
                        </Typography>
                        {formik.values.observations[index]
                          .combinationWithMedicine?.length !== 1 && (
                          <ButtonAddDrug
                            type="button"
                            onClick={() => deleteCombination(index, idx)}
                          >
                            Usuń kombinację
                            <Bin />
                          </ButtonAddDrug>
                        )}
                      </Grid>
                      <Grid item xs={12} mt={1}>
                        <SingleSelect
                          isRequired={true}
                          defaultValue={"Wybierz"}
                          isError={
                            Array.isArray(formik.touched.observations) &&
                            Array.isArray(formik.errors.observations) &&
                            typeof formik.errors.observations[index] ===
                              "object" &&
                            formik.touched.observations[index]
                              ?.combinationWithMedicine?.[idx]
                              ?.combinationDrugName &&
                            Boolean(
                              (
                                (
                                  formik.errors.observations[
                                    index
                                  ] as FormikErrors<IObservations>
                                )?.combinationWithMedicine?.[
                                  idx
                                ] as FormikErrors<ICombinations>
                              )?.combinationDrugName
                            )
                          }
                          label={"Kombinacja z jakim lekiem"}
                          handleChange={(value) =>
                            setCombinationOption(
                              value,
                              "combinationDrugName",
                              index,
                              idx
                            )
                          }
                          selectedValue={
                            formik.values.observations[index]
                              ?.combinationWithMedicine[idx]
                              .combinationDrugName || ""
                          }
                          elementName={`observations[${index}].combinationWithMedicine[${idx}].combinationDrugName`}
                          options={combinationsOptions}
                        />
                      </Grid>
                      {formik.values.observations[index]
                        .combinationWithMedicine[idx].combinationDrugName ===
                        "Inne" && (
                        <Grid item xs={12}>
                          <Input
                            isRequired={true}
                            label={"nazwa innego leku*"}
                            isUpperCase={true}
                            name={`observations[${index}].combinationWithMedicine[${idx}].otherCombinationDrug`}
                            type={"text"}
                            colorLabel={theme.colors.grey1}
                            value={
                              formik.values.observations?.[index]
                                ?.combinationWithMedicine[idx]
                                .otherCombinationDrug || ""
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            borderColor={theme.colors.grey5}
                            placeholderColor={theme.colors.grey4}
                            background={theme.colors.white}
                            placeholder={"Wpisz"}
                            isError={
                              Array.isArray(formik.touched.observations) &&
                              Array.isArray(formik.errors.observations) &&
                              typeof formik.errors.observations[index] ===
                                "object" &&
                              formik.touched.observations[index]
                                ?.combinationWithMedicine?.[idx]
                                ?.otherCombinationDrug &&
                              Boolean(
                                (
                                  (
                                    formik.errors.observations[
                                      index
                                    ] as FormikErrors<IObservations>
                                  )?.combinationWithMedicine?.[
                                    idx
                                  ] as FormikErrors<ICombinations>
                                )?.otherCombinationDrug
                              )
                            }
                          />
                        </Grid>
                      )}
                      <Grid
                        container
                        display={"flex"}
                        gap={"10px"}
                        alignItems={"flex-end"}
                      >
                        <Grid>
                          <Input
                            isRequired={true}
                            label={"Dawka"}
                            isUpperCase={true}
                            name={`observations[${index}].combinationWithMedicine[${idx}].combinationDose`}
                            type={"number"}
                            onChangeCustom={() =>
                              formik.setFieldValue(
                                `observations[${index}].combinationWithMedicine[${idx}].combinationDose`,
                                null
                              )
                            }
                            maxWidth={"163px"}
                            colorLabel={theme.colors.grey1}
                            value={
                              formik.values.observations?.[index]
                                ?.combinationWithMedicine[idx].combinationDose
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            borderColor={theme.colors.grey5}
                            placeholderColor={theme.colors.grey4}
                            background={theme.colors.white}
                            placeholder={"Wpisz"}
                            isError={
                              Array.isArray(formik.touched.observations) &&
                              Array.isArray(formik.errors.observations) &&
                              typeof formik.errors.observations[index] ===
                                "object" &&
                              formik.touched.observations[index]
                                ?.combinationWithMedicine?.[idx]
                                ?.combinationDose &&
                              Boolean(
                                (
                                  (
                                    formik.errors.observations[
                                      index
                                    ] as FormikErrors<IObservations>
                                  )?.combinationWithMedicine?.[
                                    idx
                                  ] as FormikErrors<ICombinations>
                                )?.combinationDose
                              )
                            }
                          />
                        </Grid>
                        <Grid pb={2}>
                          <RadioForm>
                            <RadioBox
                              id={`observations[${index}].combinationWithMedicine[${idx}].combinationUnit1`}
                              name={`observations[${index}].combinationWithMedicine[${idx}].combinationUnit`}
                              value={"g/m"}
                              isUpperCase={false}
                              checked={combination.combinationUnit === "g/m"}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  `observations[${index}].combinationWithMedicine[${idx}].combinationUnit`,
                                  e.target.value
                                );
                                formik.setFieldValue(
                                  `observations[${index}].combinationWithMedicine[${idx}].otherCombinationUnit`,
                                  ""
                                );
                              }}
                              isError={
                                Array.isArray(formik.touched.observations) &&
                                Array.isArray(formik.errors.observations) &&
                                typeof formik.errors.observations[index] ===
                                  "object" &&
                                formik.touched.observations[index]
                                  ?.combinationWithMedicine?.[idx]
                                  ?.combinationUnit &&
                                Boolean(
                                  (
                                    (
                                      formik.errors.observations[
                                        index
                                      ] as FormikErrors<IObservations>
                                    )?.combinationWithMedicine?.[
                                      idx
                                    ] as FormikErrors<ICombinations>
                                  )?.combinationUnit
                                )
                              }
                            />
                            <RadioBox
                              id={`observations[${index}].combinationWithMedicine[${idx}].combinationUnit2`}
                              name={`observations[${index}].combinationWithMedicine[${idx}].combinationUnit`}
                              value={"mg/kg m.c."}
                              isUpperCase={false}
                              checked={
                                combination.combinationUnit === "mg/kg m.c."
                              }
                              onChange={(e) => {
                                formik.setFieldValue(
                                  `observations[${index}].combinationWithMedicine[${idx}].combinationUnit`,
                                  e.target.value
                                );
                                formik.setFieldValue(
                                  `observations[${index}].combinationWithMedicine[${idx}].otherCombinationUnit`,
                                  ""
                                );
                              }}
                              isError={
                                Array.isArray(formik.touched.observations) &&
                                Array.isArray(formik.errors.observations) &&
                                typeof formik.errors.observations[index] ===
                                  "object" &&
                                formik.touched.observations[index]
                                  ?.combinationWithMedicine?.[idx]
                                  ?.combinationUnit &&
                                Boolean(
                                  (
                                    (
                                      formik.errors.observations[
                                        index
                                      ] as FormikErrors<IObservations>
                                    )?.combinationWithMedicine?.[
                                      idx
                                    ] as FormikErrors<ICombinations>
                                  )?.combinationUnit
                                )
                              }
                            />
                            <RadioBox
                              id={`observations[${index}].combinationWithMedicine[${idx}].combinationUnit3`}
                              name={`observations[${index}].combinationWithMedicine[${idx}].combinationUnit`}
                              value={"Inna jednostka"}
                              isUpperCase={false}
                              checked={
                                combination.combinationUnit === "Inna jednostka"
                              }
                              onChange={(e) =>
                                formik.setFieldValue(
                                  `observations[${index}].combinationWithMedicine[${idx}].combinationUnit`,
                                  e.target.value
                                )
                              }
                              isError={
                                Array.isArray(formik.touched.observations) &&
                                Array.isArray(formik.errors.observations) &&
                                typeof formik.errors.observations[index] ===
                                  "object" &&
                                formik.touched.observations[index]
                                  ?.combinationWithMedicine?.[idx]
                                  ?.combinationUnit &&
                                Boolean(
                                  (
                                    (
                                      formik.errors.observations[
                                        index
                                      ] as FormikErrors<IObservations>
                                    )?.combinationWithMedicine?.[
                                      idx
                                    ] as FormikErrors<ICombinations>
                                  )?.combinationUnit
                                )
                              }
                            />
                          </RadioForm>
                        </Grid>
                        {formik.values.observations[index]
                          .combinationWithMedicine[idx].combinationUnit ===
                          "Inna jednostka" && (
                          <Grid>
                            <Input
                              isRequired={true}
                              label={"Inna jednostka"}
                              isUpperCase={true}
                              name={`observations[${index}].combinationWithMedicine[${idx}].otherCombinationUnit`}
                              type={"text"}
                              maxWidth={"163px"}
                              colorLabel={theme.colors.grey1}
                              value={
                                formik.values.observations?.[index]
                                  ?.combinationWithMedicine[idx]
                                  .otherCombinationUnit || ""
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              borderColor={theme.colors.grey5}
                              placeholderColor={theme.colors.grey4}
                              background={theme.colors.white}
                              placeholder={"Wpisz"}
                              isError={
                                Array.isArray(formik.touched.observations) &&
                                Array.isArray(formik.errors.observations) &&
                                typeof formik.errors.observations[index] ===
                                  "object" &&
                                formik.touched.observations[index]
                                  ?.combinationWithMedicine?.[idx]
                                  ?.otherCombinationUnit &&
                                Boolean(
                                  (
                                    (
                                      formik.errors.observations[
                                        index
                                      ] as FormikErrors<IObservations>
                                    )?.combinationWithMedicine?.[
                                      idx
                                    ] as FormikErrors<ICombinations>
                                  )?.otherCombinationUnit
                                )
                              }
                            />
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <Input
                            before={
                              <Label
                                isUpperCase={false}
                                style={{ margin: "8px 0 0" }}
                                color={theme.colors.orange}
                                size={"superSmall"}
                                label={"liczba tygodni"}
                              />
                            }
                            maxWidth={"72px"}
                            isRequired={true}
                            label={"Zakres czasu przyjmowania"}
                            isUpperCase={true}
                            name={`observations[${index}].combinationWithMedicine[${idx}].combinationDuration`}
                            type={"number"}
                            onChangeCustom={() =>
                              formik.setFieldValue(
                                `observations[${index}].combinationWithMedicine[${idx}].combinationDuration`,
                                null
                              )
                            }
                            colorLabel={theme.colors.grey1}
                            value={
                              formik.values.observations?.[index]
                                ?.combinationWithMedicine[idx]
                                .combinationDuration
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            borderColor={theme.colors.grey5}
                            placeholderColor={theme.colors.grey4}
                            background={theme.colors.white}
                            placeholder={"Wpisz"}
                            isError={
                              Array.isArray(formik.touched.observations) &&
                              Array.isArray(formik.errors.observations) &&
                              typeof formik.errors.observations[index] ===
                                "object" &&
                              formik.touched.observations[index]
                                ?.combinationWithMedicine?.[idx]
                                ?.combinationDuration &&
                              Boolean(
                                (
                                  (
                                    formik.errors.observations[
                                      index
                                    ] as FormikErrors<IObservations>
                                  )?.combinationWithMedicine?.[
                                    idx
                                  ] as FormikErrors<ICombinations>
                                )?.combinationDuration
                              )
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              )}

            {formik.values.observations[index].monotherapy === "kombinacja" && (
              <Grid
                mt={4}
                mb={2}
                display={"flex"}
                item
                xs={12}
                justifyContent={"center"}
              >
                <ButtonAddDrug
                  type="button"
                  onClick={() => addOtherCombination(index)}
                >
                  Dodaj kombinację z lekiem
                  <Plus />
                </ButtonAddDrug>
              </Grid>
            )}

            <Divider
              style={{ width: "100%" }}
              variant={"fullWidth"}
              sx={{ height: "2px", margin: "0 0 16px 0" }}
            />
            {lzsCharacter === "postać obwodowa" && (
              <>
                <Grid
                  xs={12}
                  item
                  pb={2}
                  display={"flex"}
                  flexDirection={"column"}
                  mt={2}
                >
                  <Label
                    size={"small"}
                    isUpperCase={false}
                    color={theme.colors.grey1}
                    label={"KRYTERIA PsARC"}
                  />
                  <RadioForm>
                    <RadioBox
                      isError={
                        Array.isArray(formik.touched.observations) &&
                        formik.touched.observations[index]?.criteriaPsarc &&
                        Array.isArray(formik.errors.observations) &&
                        Boolean(
                          (
                            formik.errors.observations[
                              index
                            ] as FormikErrors<IObservations>
                          )?.criteriaPsarc
                        )
                      }
                      id={`criteriaPsarc1-${index}`}
                      name={`observations[${index}].criteriaPsarc`}
                      value={"das 28"}
                      checked={
                        formik.values.observations[index].criteriaPsarc ===
                        "das 28"
                      }
                      onChange={(e) => {
                        formik.handleChange(e);
                        resetForDasTreatments(index);
                      }}
                    />
                    <RadioBox
                      isError={
                        Array.isArray(formik.touched.observations) &&
                        formik.touched.observations[index]?.criteriaPsarc &&
                        Array.isArray(formik.errors.observations) &&
                        Boolean(
                          (
                            formik.errors.observations[
                              index
                            ] as FormikErrors<IObservations>
                          )?.criteriaPsarc
                        )
                      }
                      id={`criteriaPsarc2-${index}`}
                      name={`observations[${index}].criteriaPsarc`}
                      value={"likert"}
                      checked={
                        formik.values.observations[index].criteriaPsarc ===
                        "likert"
                      }
                      onChange={(e) => {
                        formik.handleChange(e);
                        resetForLikertTreatments(index);
                      }}
                    />
                  </RadioForm>
                </Grid>
                {formik.values.observations[index].criteriaPsarc ===
                  "das 28" && (
                  <>
                    <Grid container display={"flex"} gap={"20px"} mb={2} mt={2}>
                      <Grid
                        item
                        pb={2}
                        display={"flex"}
                        flexDirection={"column"}
                      >
                        <Label
                          size={"small"}
                          isUpperCase={true}
                          color={theme.colors.grey1}
                          label={"Wynik das 28"}
                          isRequired={true}
                        />
                        <RadioForm>
                          <RadioBox
                            isError={
                              Array.isArray(formik.touched.observations) &&
                              formik.touched.observations[index]?.das28Result &&
                              Array.isArray(formik.errors.observations) &&
                              Boolean(
                                (
                                  formik.errors.observations[
                                    index
                                  ] as FormikErrors<IObservations>
                                )?.das28Result
                              )
                            }
                            id={`das28Result1-${index}`}
                            name={`observations[${index}].das28Result`}
                            value={"crp"}
                            checked={
                              formik.values.observations[index].das28Result ===
                              "crp"
                            }
                            onChange={formik.handleChange}
                          />
                          <RadioBox
                            isError={
                              Array.isArray(formik.touched.observations) &&
                              formik.touched.observations[index]?.das28Result &&
                              Array.isArray(formik.errors.observations) &&
                              Boolean(
                                (
                                  formik.errors.observations[
                                    index
                                  ] as FormikErrors<IObservations>
                                )?.das28Result
                              )
                            }
                            id={`das28Result2-${index}`}
                            name={`observations[${index}].das28Result`}
                            value={"ob"}
                            checked={
                              formik.values.observations[index].das28Result ===
                              "ob"
                            }
                            onChange={formik.handleChange}
                          />
                        </RadioForm>
                      </Grid>
                      <Grid>
                        <Input
                          isRequired={true}
                          label={"Wynik"}
                          isUpperCase={true}
                          name={`observations[${index}].result`}
                          type={"number"}
                          onChangeCustom={() =>
                            formik.setFieldValue(
                              `observations[${index}].result`,
                              null
                            )
                          }
                          maxWidth={"163px"}
                          colorLabel={theme.colors.grey1}
                          value={formik.values.observations?.[index]?.result}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          borderColor={theme.colors.grey5}
                          placeholderColor={theme.colors.grey4}
                          background={theme.colors.white}
                          placeholder={"Wpisz"}
                          isError={
                            formik.touched.observations?.[index]?.result &&
                            Boolean(
                              (
                                formik.errors.observations?.[
                                  index
                                ] as FormikErrors<IObservations>
                              )?.result
                            )
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        justifyContent={"space-between"}
                        display={"flex"}
                        flexDirection={"column"}
                      >
                        <Label
                          size={"small"}
                          isUpperCase={true}
                          color={theme.colors.grey1}
                          label={"Wynik skali"}
                        />
                        <Typography
                          variant="caption"
                          color={theme.colors.grey1}
                        >
                          &lt;2,6 - remisja <br />
                          &le;3,2 – aktywność mała <br />
                          &gt;3,2 i &le;5,1 – aktywność umiarkowana <br />
                          &gt;5,1 – aktywność duża
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
                {formik.values.observations[index].criteriaPsarc ===
                  "likert" && (
                  <>
                    <Grid container display={"flex"} gap={"20px"} mt={2}>
                      <Grid item xs={5}>
                        <SingleSelect
                          isRequired={true}
                          defaultValue={"Wybierz"}
                          isError={
                            Array.isArray(formik.touched.observations) &&
                            formik.touched.observations[index]?.likertPatient &&
                            Array.isArray(formik.errors.observations) &&
                            Boolean(
                              (
                                formik.errors.observations[
                                  index
                                ] as FormikErrors<IObservations>
                              )?.likertPatient
                            )
                          }
                          label={"Wynik w skali Likerta pacjent"}
                          handleChange={(value) =>
                            setOption(value, "likertPatient", index)
                          }
                          selectedValue={
                            formik.values.observations[index]?.likertPatient ??
                            ""
                          }
                          elementName={`observations[${index}].likertPatient`}
                          options={likertOptions}
                        />
                      </Grid>
                      <Grid>
                        <SingleSelect
                          isRequired={true}
                          defaultValue={"Wybierz"}
                          isError={
                            Array.isArray(formik.touched.observations) &&
                            formik.touched.observations[index]?.likertDoctor &&
                            Array.isArray(formik.errors.observations) &&
                            Boolean(
                              (
                                formik.errors.observations[
                                  index
                                ] as FormikErrors<IObservations>
                              )?.likertDoctor
                            )
                          }
                          label={"Wynik w skali Likerta lekarz"}
                          handleChange={(value) =>
                            setOption(value, "likertDoctor", index)
                          }
                          selectedValue={
                            formik.values.observations[index]?.likertDoctor ??
                            ""
                          }
                          elementName={`observations[${index}].likertDoctor`}
                          options={likertOptions}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid item xs={5}>
                        <SingleSelect
                          isRequired={true}
                          defaultValue={"Wybierz"}
                          isError={
                            Array.isArray(formik.touched.observations) &&
                            formik.touched.observations[index]
                              ?.generalAssessment &&
                            Array.isArray(formik.errors.observations) &&
                            Boolean(
                              (
                                formik.errors.observations[
                                  index
                                ] as FormikErrors<IObservations>
                              )?.generalAssessment
                            )
                          }
                          label={"OGÓLNA OCENA STANU CHOROBY"}
                          handleChange={(value) =>
                            setOption(value, "generalAssessment", index)
                          }
                          selectedValue={
                            formik.values.observations[index]
                              ?.generalAssessment ?? ""
                          }
                          elementName={`observations[${index}].generalAssessment`}
                          options={scaleOptions}
                        />
                      </Grid>
                    </Grid>
                    <Grid container display={"flex"} gap={"20px"}>
                      <Grid item xs={5}>
                        <Input
                          label={"Liczba bolesnych stawów"}
                          isUpperCase={true}
                          name={`observations[${index}].tenderJointsCount`}
                          type={"number"}
                          onChangeCustom={() =>
                            formik.setFieldValue(
                              `observations[${index}].tenderJointsCount`,
                              null
                            )
                          }
                          maxWidth={"163px"}
                          colorLabel={theme.colors.grey1}
                          value={
                            formik.values.observations?.[index]
                              ?.tenderJointsCount
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          borderColor={theme.colors.grey5}
                          placeholderColor={theme.colors.grey4}
                          background={theme.colors.white}
                          placeholder={"Wpisz"}
                          isError={
                            formik.touched.observations?.[index]
                              ?.tenderJointsCount &&
                            Boolean(
                              (
                                formik.errors.observations?.[
                                  index
                                ] as FormikErrors<IObservations>
                              )?.tenderJointsCount
                            )
                          }
                        />
                      </Grid>
                      <Grid>
                        <Input
                          label={"Liczba obrzękniętych stawów"}
                          isUpperCase={true}
                          name={`observations[${index}].swollenJointsCount`}
                          type={"number"}
                          onChangeCustom={() =>
                            formik.setFieldValue(
                              `observations[${index}].swollenJointsCount`,
                              null
                            )
                          }
                          maxWidth={"163px"}
                          colorLabel={theme.colors.grey1}
                          value={
                            formik.values.observations?.[index]
                              ?.swollenJointsCount
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          borderColor={theme.colors.grey5}
                          placeholderColor={theme.colors.grey4}
                          background={theme.colors.white}
                          placeholder={"Wpisz"}
                          isError={
                            formik.touched.observations?.[index]
                              ?.swollenJointsCount &&
                            Boolean(
                              (
                                formik.errors.observations?.[
                                  index
                                ] as FormikErrors<IObservations>
                              )?.swollenJointsCount
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            )}
            {lzsCharacter === "postać osiowa" && (
              <>
                <Grid container display={"flex"} gap={"20px"} mb={2} mt={2}>
                  <Grid item pb={2} display={"flex"} flexDirection={"column"}>
                    <Label
                      size={"small"}
                      isUpperCase={true}
                      color={theme.colors.grey1}
                      label={"Podaj wynik w skali"}
                      isRequired={true}
                    />
                    <RadioForm>
                      <RadioBox
                        isError={
                          Array.isArray(formik.touched.observations) &&
                          formik.touched.observations[index]
                            ?.resultScaleOption &&
                          Array.isArray(formik.errors.observations) &&
                          Boolean(
                            (
                              formik.errors.observations[
                                index
                              ] as FormikErrors<IObservations>
                            )?.resultScaleOption
                          )
                        }
                        id={`resultScaleOption2-${index}`}
                        name={`observations[${index}].resultScaleOption`}
                        value={"BASDAI"}
                        checked={
                          formik.values.observations[index]
                            .resultScaleOption === "BASDAI"
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            `observations[${index}].resultScaleOption`,
                            e.target.value
                          );
                        }}
                      />
                      <RadioBox
                        isError={
                          Array.isArray(formik.touched.observations) &&
                          formik.touched.observations[index]
                            ?.resultScaleOption &&
                          Array.isArray(formik.errors.observations) &&
                          Boolean(
                            (
                              formik.errors.observations[
                                index
                              ] as FormikErrors<IObservations>
                            )?.resultScaleOption
                          )
                        }
                        id={`resultScaleOption22-${index}`}
                        name={`observations[${index}].resultScaleOption`}
                        value={"ASDAS"}
                        checked={
                          formik.values.observations[index]
                            .resultScaleOption === "ASDAS"
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            `observations[${index}].resultScaleOption`,
                            e.target.value
                          );
                        }}
                      />
                    </RadioForm>
                  </Grid>
                  <Grid>
                    <Input
                      isRequired={true}
                      label={"Wynik"}
                      isUpperCase={true}
                      name={`observations[${index}].resultScale`}
                      type={"number"}
                      onChangeCustom={() =>
                        formik.setFieldValue(
                          `observations[${index}].resultScale`,
                          null
                        )
                      }
                      maxWidth={"163px"}
                      colorLabel={theme.colors.grey1}
                      value={formik.values.observations?.[index]?.resultScale}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      borderColor={theme.colors.grey5}
                      placeholderColor={theme.colors.grey4}
                      background={theme.colors.white}
                      placeholder={"Wpisz"}
                      isError={
                        formik.touched.observations?.[index]?.resultScale &&
                        Boolean(
                          (
                            formik.errors.observations?.[
                              index
                            ] as FormikErrors<IObservations>
                          )?.resultScale
                        )
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container display={"flex"} gap={"20px"}>
                  <Grid item xs={5}>
                    <SingleSelect
                      isRequired={true}
                      defaultValue={"Wybierz"}
                      isError={
                        Array.isArray(formik.touched.observations) &&
                        formik.touched.observations[index]?.degreeOfBack &&
                        Array.isArray(formik.errors.observations) &&
                        Boolean(
                          (
                            formik.errors.observations[
                              index
                            ] as FormikErrors<IObservations>
                          )?.degreeOfBack
                        )
                      }
                      label={"Stopień bólu kręgosłupa"}
                      handleChange={(value) =>
                        setOption(value, "degreeOfBack", index)
                      }
                      selectedValue={
                        formik.values.observations[index]?.degreeOfBack ?? ""
                      }
                      elementName={`observations[${index}].degreeOfBack`}
                      options={scaleOptions}
                    />
                  </Grid>
                  <Grid>
                    <SingleSelect
                      isRequired={true}
                      defaultValue={"Wybierz"}
                      isError={
                        Array.isArray(formik.touched.observations) &&
                        formik.touched.observations[index]
                          ?.generalAssessment2 &&
                        Array.isArray(formik.errors.observations) &&
                        Boolean(
                          (
                            formik.errors.observations[
                              index
                            ] as FormikErrors<IObservations>
                          )?.generalAssessment2
                        )
                      }
                      label={"OGÓLNA OCENA STANU CHOROBY"}
                      handleChange={(value) =>
                        setOption(value, "generalAssessment2", index)
                      }
                      selectedValue={
                        formik.values.observations[index]?.generalAssessment2 ??
                        ""
                      }
                      elementName={`observations[${index}].generalAssessment2`}
                      options={scaleOptions}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <Grid item xs={12} mt={2}>
              <Input
                label={"Zmiany w zakresie skal łuszczycy"}
                isUpperCase={true}
                name={`observations[${index}].psoriasisChanges`}
                type={"textarea"}
                colorLabel={theme.colors.grey1}
                value={formik.values.observations?.[index]?.psoriasisChanges}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                borderColor={theme.colors.grey5}
                placeholderColor={theme.colors.grey4}
                background={theme.colors.white}
                placeholder={"Wpisz"}
                isError={
                  formik.touched.observations?.[index]?.psoriasisChanges &&
                  Boolean(
                    (
                      formik.errors.observations?.[
                        index
                      ] as FormikErrors<IObservations>
                    )?.psoriasisChanges
                  )
                }
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid mb={1} display={"flex"} item xs={12} justifyContent={"center"}>
        <ButtonAddDrug type="button" onClick={addObservation}>
          Dodaj obserwację
          <Plus />
        </ButtonAddDrug>
      </Grid>
      <Grid item pb={2} display={"flex"} flexDirection={"column"}>
        <Label
          size={"small"}
          isUpperCase={true}
          color={theme.colors.grey1}
          label={"Glikokortykosteroidy (GKS)"}
          isRequired={true}
        />
        <RadioForm>
          <RadioBox
            isError={formik.touched.gks4 && Boolean(formik.errors.gks4)}
            id={"gks4"}
            name={"gks4"}
            value={"tak"}
            checked={formik.values.gks4 === "tak"}
            onChange={(e) => {
              formik.handleChange(e);
            }}
          />
          <RadioBox
            isError={formik.touched.gks4 && Boolean(formik.errors.gks4)}
            id={"gks42"}
            name={"gks4"}
            value={"nie"}
            checked={formik.values.gks4 === "nie"}
            onChange={(e) => {
              formik.handleChange(e);
            }}
          />
        </RadioForm>
      </Grid>
    </Grid>
  );
});
