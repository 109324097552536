import { observer } from "mobx-react";
import { FormikErrors } from "formik";

// hooks
import { useStage04ViewModel } from "./Stage04.vm";

// components
import { Divider, Grid, Typography } from "@mui/material";
import { Label } from "../../../../../../../../shared/ui/label/Label";
import { Input } from "../../../../../../../../shared/ui/input/Input";
import { SingleSelect } from "../../../../../../../../shared/ui/singleSelect/SingleSelect";
import { RadioBox } from "../../../../../../../../shared/ui/radioBox/RadioBox";

// lib
import theme from "../../../../../../../../../theme/theme";

//icons
import { ReactComponent as Bin } from "../../../../../../../../../assets/icon/abbvieBin.svg";
import { ReactComponent as Plus } from "../../../../../../../../../assets/icon/abbviePlus.svg";

//types, helpers, styles
import { IObservations } from "./type";
import { vasOptions } from "./Stage04.helper";
import React from "react";
import { DateInput } from "../../../../../../../../shared/ui/date/Date";
import { RadioForm } from "../../../styles";
import { ButtonAddDrug } from "../stage02/Stage02.styled";

export const Stage04 = observer(() => {
  const {
    formik,
    setOption,
    modifyObservations,
    handleYes,
    handleNo,
    addObservation,
    deleteMode,
    deleteObservation,
  } = useStage04ViewModel();

  return (
    <Grid
      container
      maxWidth={"714px"}
      display={"flex"}
      margin={"0 auto"}
      my={4}
      component="form"
    >
      <Grid item mb={5} display={"flex"} flexDirection={"column"}>
        <Label
          size={"small"}
          isUpperCase={true}
          color={theme.colors.grey1}
          label={"Czy lek Rinvoq był używany w pierwszej linii leczenia?"}
          isRequired={true}
        />
        <RadioForm>
          <RadioBox
            isError={formik.touched.wasUsed && Boolean(formik.errors.wasUsed)}
            id={"wasUsed"}
            name={"wasUsed"}
            value={"tak"}
            checked={formik.values.wasUsed === "tak"}
            onChange={(e) => {
              handleYes();
              formik.handleChange(e);
            }}
          />
          <RadioBox
            isError={formik.touched.wasUsed && Boolean(formik.errors.wasUsed)}
            id={"wasUsed2"}
            name={"wasUsed"}
            value={"nie"}
            checked={formik.values.wasUsed === "nie"}
            onChange={(e) => {
              handleNo();
              formik.handleChange(e);
            }}
          />
        </RadioForm>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography
          variant={"h6"}
          color={theme.colors.grey1}
          textTransform={"uppercase"}
          fontWeight={700}
        >
          Wyniki przed włączeniem leku Rinvoq
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <DateInput
          disableFuture={true}
          openTo={"year"}
          inputFormat={"yyyy/MM/dd"}
          views={["year", "month", "day"]}
          isRequired={true}
          maxWidth={"254px"}
          label={<>Data włączenia leku Rinvoq</>}
          isUpperCase={true}
          name={"drugInitiationDate"}
          size={"small"}
          color={theme.colors.grey1}
          value={formik.values.drugInitiationDate ?? null}
          onClose={() => {
            formik.setFieldTouched("drugInitiationDate", true);
            formik.validateField("drugInitiationDate");
          }}
          onChange={(e, context) => {
            formik.setFieldValue("drugInitiationDate", e);
          }}
          onBlur={() => {
            formik.setFieldTouched("drugInitiationDate", true);
            setTimeout(() => {
              formik.validateField("drugInitiationDate");
            }, 0);
          }}
          isError={
            formik.touched.drugInitiationDate &&
            Boolean(formik.errors.drugInitiationDate)
          }
        />
      </Grid>
      <Grid container display={"flex"} gap={"20px"} mt={2}>
        <Grid item pb={2} display={"flex"} flexDirection={"column"}>
          <Label
            size={"small"}
            isUpperCase={true}
            color={theme.colors.grey1}
            label={"podaj wynik w skali"}
            isRequired={true}
          />
          <RadioForm>
            <RadioBox
              isError={
                formik.touched.resultScaleOption4 &&
                Boolean(formik.errors.resultScaleOption4)
              }
              id={"resultScaleOption4"}
              name={"resultScaleOption4"}
              value={"BASDAI"}
              checked={formik.values.resultScaleOption4 === "BASDAI"}
              onChange={formik.handleChange}
            />
            <RadioBox
              isError={
                formik.touched.resultScaleOption4 &&
                Boolean(formik.errors.resultScaleOption4)
              }
              id={"resultScaleOption42"}
              name={"resultScaleOption4"}
              value={"ASDAS"}
              checked={formik.values.resultScaleOption4 === "ASDAS"}
              onChange={formik.handleChange}
            />
          </RadioForm>
        </Grid>
        <Grid item xs={2}>
          <Input
            isRequired={true}
            label={"Wynik"}
            isUpperCase={true}
            name={"result4"}
            type={"number"}
            onChangeCustom={() => formik.setFieldValue("result4", null)}
            maxWidth={"163px"}
            colorLabel={theme.colors.grey1}
            value={formik.values.result4}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz"}
            isError={formik.touched.result4 && Boolean(formik.errors.result4)}
          />
        </Grid>
      </Grid>

      <Grid container display={"flex"} gap={"20px"} mb={2} mt={2}>
        <Grid item xs={3}>
          <SingleSelect
            isRequired={true}
            defaultValue={"Wybierz"}
            isError={
              formik.touched.vasResult4 && Boolean(formik.errors.vasResult4)
            }
            label={"Wynik Vas"}
            handleChange={(value) => setOption(value, "vasResult4")}
            selectedValue={formik.values.vasResult4 ?? ""}
            elementName={"vasResult4"}
            options={vasOptions}
          />
        </Grid>
        <Grid item xs={3}>
          <Input
            isRequired={true}
            label={"Wynik CRP"}
            isUpperCase={true}
            name={"crpResult4"}
            type={"number"}
            onChangeCustom={() => formik.setFieldValue("crpResult4", null)}
            maxWidth={"163px"}
            colorLabel={theme.colors.grey1}
            value={formik.values.crpResult4}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz"}
            isError={
              formik.touched.crpResult4 && Boolean(formik.errors.crpResult4)
            }
          />
        </Grid>
        <Grid item xs={3}>
          <Input
            onChangeCustom={() => formik.setFieldValue("obResult4", null)}
            label={"Wynik OB"}
            isUpperCase={true}
            name={"obResult4"}
            type={"number"}
            maxWidth={"163px"}
            colorLabel={theme.colors.grey1}
            value={formik.values.obResult4}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz"}
            isError={
              formik.touched.obResult4 && Boolean(formik.errors.obResult4)
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={12} mb={2} mt={3}>
        <Typography
          variant={"h6"}
          color={theme.colors.grey1}
          textTransform={"uppercase"}
          fontWeight={700}
        >
          Obserwacja na leku Rinvoq
        </Typography>
      </Grid>
      <Grid container display={"flex"} gap={"20px"} mb={2}>
        <Grid item pb={2} display={"flex"} flexDirection={"column"}>
          <Label
            size={"small"}
            isUpperCase={true}
            color={theme.colors.grey1}
            label={"Interwały obserwacji"}
            isRequired={true}
          />
          <RadioForm>
            <RadioBox
              isError={
                formik.touched.observationIntervals &&
                Boolean(formik.errors.observationIntervals)
              }
              id={"observationIntervals"}
              name={"observationIntervals"}
              value={"co 1 msc."}
              checked={formik.values.observationIntervals === "co 1 msc."}
              onChange={(e) => {
                formik.handleChange(e);
                modifyObservations(e.target.value);
              }}
            />
            <RadioBox
              isError={
                formik.touched.observationIntervals &&
                Boolean(formik.errors.observationIntervals)
              }
              id={"observationIntervals2"}
              name={"observationIntervals"}
              value={"co 3 msc."}
              checked={formik.values.observationIntervals === "co 3 msc."}
              onChange={(e) => {
                formik.handleChange(e);
                modifyObservations(e.target.value);
              }}
            />
            <RadioBox
              isError={
                formik.touched.observationIntervals &&
                Boolean(formik.errors.observationIntervals)
              }
              id={"observationIntervals3"}
              name={"observationIntervals"}
              value={"co 6 msc."}
              checked={formik.values.observationIntervals === "co 6 msc."}
              onChange={(e) => {
                formik.handleChange(e);
                modifyObservations(e.target.value);
              }}
            />
          </RadioForm>
        </Grid>
      </Grid>
      {formik.values.observations.length > 0 && (
        <>
          <Divider
            style={{ width: "100%" }}
            variant={"fullWidth"}
            sx={{ height: "2px", margin: "0 0 24px 0" }}
          />
          <Grid
            item
            xs={12}
            mb={2}
            mt={2}
            display={"flex"}
            justifyContent={"center"}
          >
            <Typography
              variant={"h6"}
              color={theme.colors.grey1}
              textTransform={"uppercase"}
              fontWeight={700}
            >
              Obserwacje
            </Typography>
          </Grid>
          <Divider
            style={{ width: "100%" }}
            variant={"fullWidth"}
            sx={{ height: "2px", margin: "16px 0 16px 0" }}
          />
        </>
      )}
      <Grid container>
        {formik.values.observations?.map((line, index) => (
          <Grid key={index} item xs={12} mb={4}>
            <Grid
              item
              xs={12}
              mt={2}
              mb={4}
              justifyContent={"space-between"}
              display={"flex"}
            >
              <Typography
                variant={"h6"}
                color={theme.colors.grey1}
                textTransform={"uppercase"}
                fontWeight={700}
              >
                Obserwacja {index + 1}
              </Typography>
            </Grid>
            {deleteMode && (
              <ButtonAddDrug
                type="button"
                onClick={() => deleteObservation(index)}
              >
                Usuń obserwację
                <Bin />
              </ButtonAddDrug>
            )}
            <Grid
              item
              xs={12}
              justifyContent={"space-between"}
              display={"flex"}
              flexDirection={"column"}
            >
              <Grid container display={"flex"} gap={"20px"}>
                <Grid item pb={2} display={"flex"} flexDirection={"column"}>
                  <Label
                    size={"small"}
                    isUpperCase={true}
                    color={theme.colors.grey1}
                    label={"Podaj wynik w skali"}
                    isRequired={true}
                  />
                  <RadioForm>
                    <RadioBox
                      isError={
                        Array.isArray(formik.touched.observations) &&
                        formik.touched.observations[index]?.resultScaleOption &&
                        Array.isArray(formik.errors.observations) &&
                        Boolean(
                          (
                            formik.errors.observations[
                              index
                            ] as FormikErrors<IObservations>
                          )?.resultScaleOption
                        )
                      }
                      id={`resultScaleOption-${index}`}
                      name={`observations[${index}].resultScaleOption`}
                      value={"BASDAI"}
                      checked={
                        formik.values.observations[index].resultScaleOption ===
                        "BASDAI"
                      }
                      onChange={(e) => {
                        formik.setFieldValue(
                          `observations[${index}].resultScaleOption`,
                          e.target.value
                        );
                      }}
                    />
                    <RadioBox
                      isError={
                        Array.isArray(formik.touched.observations) &&
                        formik.touched.observations[index]?.resultScaleOption &&
                        Array.isArray(formik.errors.observations) &&
                        Boolean(
                          (
                            formik.errors.observations[
                              index
                            ] as FormikErrors<IObservations>
                          )?.resultScaleOption
                        )
                      }
                      id={`resultScaleOption2-${index}`}
                      name={`observations[${index}].resultScaleOption`}
                      value={"ASDAS"}
                      checked={
                        formik.values.observations[index].resultScaleOption ===
                        "ASDAS"
                      }
                      onChange={(e) => {
                        formik.setFieldValue(
                          `observations[${index}].resultScaleOption`,
                          e.target.value
                        );
                      }}
                    />
                  </RadioForm>
                </Grid>
                <Grid item xs={2}>
                  <Input
                    isRequired={true}
                    label={"Wynik"}
                    isUpperCase={true}
                    name={`observations[${index}].result`}
                    type={"number"}
                    onChangeCustom={() =>
                      formik.setFieldValue(
                        `observations[${index}].result`,
                        null
                      )
                    }
                    maxWidth={"163px"}
                    colorLabel={theme.colors.grey1}
                    value={formik.values.observations?.[index]?.result}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    borderColor={theme.colors.grey5}
                    placeholderColor={theme.colors.grey4}
                    background={theme.colors.white}
                    placeholder={"Wpisz"}
                    isError={
                      Array.isArray(formik.touched.observations) &&
                      formik.touched.observations[index]?.result &&
                      Array.isArray(formik.errors.observations) &&
                      Boolean(
                        (
                          formik.errors.observations[
                            index
                          ] as FormikErrors<IObservations>
                        )?.result
                      )
                    }
                  />
                </Grid>
              </Grid>
              <Grid container display={"flex"} gap={"20px"} mb={2} mt={2}>
                <Grid item xs={3}>
                  <SingleSelect
                    isRequired={true}
                    defaultValue={"Wybierz"}
                    isError={
                      Array.isArray(formik.touched.observations) &&
                      formik.touched.observations[index]?.vasResult &&
                      Array.isArray(formik.errors.observations) &&
                      Boolean(
                        (
                          formik.errors.observations[
                            index
                          ] as FormikErrors<IObservations>
                        )?.vasResult
                      )
                    }
                    label={"Wynik Vas"}
                    handleChange={(value) =>
                      setOption(value, "vasResult", index)
                    }
                    selectedValue={
                      formik.values.observations[index]?.vasResult ?? ""
                    }
                    elementName={`observations[${index}].vasResult`}
                    options={vasOptions}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Input
                    isRequired={true}
                    label={"Wynik CRP"}
                    isUpperCase={true}
                    name={`observations[${index}].crpResult`}
                    type={"number"}
                    colorLabel={theme.colors.grey1}
                    value={formik.values.observations?.[index]?.crpResult}
                    onChangeCustom={() =>
                      formik.setFieldValue(
                        `observations[${index}].crpResult`,
                        null
                      )
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    borderColor={theme.colors.grey5}
                    placeholderColor={theme.colors.grey4}
                    background={theme.colors.white}
                    placeholder={"Wpisz"}
                    isError={
                      formik.touched.observations?.[index]?.crpResult &&
                      Boolean(
                        (
                          formik.errors.observations?.[
                            index
                          ] as FormikErrors<IObservations>
                        )?.crpResult
                      )
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <Input
                    label={"Wynik OB"}
                    onChangeCustom={() =>
                      formik.setFieldValue(
                        `observations[${index}].obResult`,
                        null
                      )
                    }
                    isUpperCase={true}
                    name={`observations[${index}].obResult`}
                    type={"number"}
                    colorLabel={theme.colors.grey1}
                    value={formik.values.observations?.[index]?.obResult}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    borderColor={theme.colors.grey5}
                    placeholderColor={theme.colors.grey4}
                    background={theme.colors.white}
                    placeholder={"Wpisz"}
                    isError={
                      formik.touched.observations?.[index]?.obResult &&
                      Boolean(
                        (
                          formik.errors.observations?.[
                            index
                          ] as FormikErrors<IObservations>
                        )?.obResult
                      )
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
        <Grid mb={1} display={"flex"} item xs={12} justifyContent={"center"}>
          <ButtonAddDrug type="button" onClick={addObservation}>
            Dodaj obserwację
            <Plus />
          </ButtonAddDrug>
        </Grid>
        <Grid item pb={2} display={"flex"} flexDirection={"column"}>
          <Label
            size={"small"}
            isUpperCase={true}
            color={theme.colors.grey1}
            label={"Glikokortykosteroidy (GKS)"}
            isRequired={true}
          />
          <RadioForm>
            <RadioBox
              isError={formik.touched.gks4 && Boolean(formik.errors.gks4)}
              id={"gks4"}
              name={"gks4"}
              value={"tak"}
              checked={formik.values.gks4 === "tak"}
              onChange={(e) => {
                formik.handleChange(e);
              }}
            />
            <RadioBox
              isError={formik.touched.gks4 && Boolean(formik.errors.gks4)}
              id={"gks42"}
              name={"gks4"}
              value={"nie"}
              checked={formik.values.gks4 === "nie"}
              onChange={(e) => {
                formik.handleChange(e);
              }}
            />
          </RadioForm>
        </Grid>
      </Grid>
    </Grid>
  );
});
