import { makeAutoObservable } from "mobx";
import { ICase, ICaseCard, IForm } from "../interfaces/admin/cases/cases";
import {
  filterByCoinfection,
  filterByCurrentPage,
  filterByForms,
  filterByStatus,
  searchByFilter,
} from "../utils/searchHelpers";
import {
  coinfectionsSettings,
  statusesSettings,
} from "../components/pages/user/myCases/MyCases.helper";
import { SelectChangeEvent } from "@mui/material";

export class CasesStore {
  filters: number[] = [];
  coinfections: number[] = [];
  filterForms: number[] = [];
  cases: ICase[] | null = null;
  forms: IForm[] | null = null;
  listOfMyCases: ICaseCard[] | null = null;
  listOfOtherCases: ICaseCard[] | null = null;
  listOfLast3Cases: ICaseCard[] | null = null;

  isCasesLoaded: boolean = false;
  isListOfMyCasesLoaded: boolean = false;
  isListOfOtherCasesLoaded: boolean = false;
  isListOfOfLast3CasesLoaded: boolean = false;

  cardsPerPage = 12;
  currentPage = 1;
  otherCardsPerPage = 12;

  searchValue: string = "";

  constructor() {
    makeAutoObservable(this);
  }

  setCases(cases: ICase[], forms: IForm[]) {
    this.cases = cases;
    this.forms = forms;
    this.isCasesLoaded = true;
  }

  setListOfMyCases(listOfMyCases: ICaseCard[], forms: IForm[]) {
    this.listOfMyCases = listOfMyCases;
    this.isListOfMyCasesLoaded = true;
    this.forms = forms;
    this.currentPage = 1;
  }

  setListOfOtherCases(listOfOtherCases: ICaseCard[], forms: IForm[]) {
    this.listOfOtherCases = listOfOtherCases;
    this.isListOfOtherCasesLoaded = true;
    this.forms = forms;
    this.currentPage = 1;
  }

  setListOfLast3Cases(listOfOfLast3Cases: ICaseCard[]) {
    this.listOfLast3Cases = listOfOfLast3Cases;
    this.isListOfOfLast3CasesLoaded = true;
    this.currentPage = 1;
  }

  resetListOfOfLast3Cases() {
    this.isListOfOfLast3CasesLoaded = false;
    this.currentPage = 1;
  }

  resetCases() {
    this.cases = null;
    this.forms = null;
    this.isCasesLoaded = false;
    this.currentPage = 1;
  }

  resetListOfMyCases() {
    this.isListOfMyCasesLoaded = false;
    this.searchValue = "";
    this.filters = [];
    this.coinfections = [];
    this.currentPage = 1;
  }

  resetListOfOtherCases() {
    this.isListOfOtherCasesLoaded = false;
    this.searchValue = "";
    this.filters = [];
    this.coinfections = [];
    this.currentPage = 1;
  }

  resetTableConfig() {
    this.cardsPerPage = 8;
    this.currentPage = 1;
    this.otherCardsPerPage = 12;
    this.setFilters([]);
  }

  setSearchValue(searchValue: string) {
    this.searchValue = searchValue;
    this.resetCurrentPage();
  }

  setFilters = (id: number[]) => {
    this.filters = [...id];
    this.resetCurrentPage();
  };

  setCoinfections = (coinfection: number[]) => {
    this.coinfections = [...coinfection];
    this.resetCurrentPage();
  };

  setFilterForms = (forms: number[]) => {
    this.filterForms = [...forms];
    this.resetCurrentPage();
  };

  switchFilters = (event: SelectChangeEvent<number[]>) => {
    const selectedValues = event.target.value as number[];
    const fullLength = Object.values(statusesSettings).length + 1;

    const allStatuses = [
      0,
      ...Object.values(statusesSettings).map((status) => status.id),
    ];
    if (selectedValues.includes(0)) {
      if (!this.filters.includes(0)) {
        this.setFilters(allStatuses);
      } else {
        const filteredValues = selectedValues.filter((value) => value !== 0);
        this.setFilters(
          fullLength > selectedValues.length
            ? filteredValues
            : [...selectedValues]
        );
      }
    } else {
      if (this.filters.includes(0)) {
        this.setFilters([]);
      } else {
        this.setFilters(
          selectedValues.length === Object.values(statusesSettings).length
            ? allStatuses
            : [...selectedValues]
        );
      }
    }

    this.resetCurrentPage();
  };

  switchCoinfectionFilters = (event: SelectChangeEvent<number[]>) => {
    const selectedValues = event.target.value as number[];
    const fullLength = Object.values(coinfectionsSettings).length;
    const allCoinfections = [
      ...Object.values(coinfectionsSettings).map(
        (coinfection) => coinfection.id
      ),
    ];

    if (selectedValues.includes(0)) {
      if (!this.coinfections.includes(0)) {
        this.setCoinfections(allCoinfections);
      } else {
        const filteredValues = selectedValues.filter((value) => value !== 0);
        this.setCoinfections(
          fullLength > selectedValues.length
            ? filteredValues
            : [...selectedValues]
        );
      }
    } else {
      if (this.coinfections.includes(0)) {
        this.setCoinfections([]);
      } else {
        this.setCoinfections(
          selectedValues.length ===
            Object.values(coinfectionsSettings).length - 1
            ? allCoinfections
            : [...selectedValues]
        );
      }
    }

    this.resetCurrentPage();
  };

  switchFormFilters = (event: SelectChangeEvent<number[]>) => {
    const selectedValues = event.target.value as number[];
    const fullLength = (this.forms ? this.forms.length : 0) + 1;
    const formsLength = fullLength - 1;
    const allForms = [
      0,
      ...(this.forms ? this.forms.map((form) => form.id) : []),
    ];

    if (selectedValues.includes(0)) {
      if (!this.filterForms.includes(0)) {
        this.setFilterForms(allForms);
      } else {
        const filteredValues = selectedValues.filter((value) => value !== 0);
        this.setFilterForms(
          fullLength > selectedValues.length
            ? filteredValues
            : [...selectedValues]
        );
      }
    } else {
      if (this.filterForms.includes(0)) {
        this.setFilterForms([]);
      } else {
        this.setFilterForms(
          selectedValues.length === formsLength ? allForms : [...selectedValues]
        );
      }
    }

    this.resetCurrentPage();
  };

  get filteredListOfCases() {
    return this.listOfMyCases
      ?.filter(filterByStatus(this.filters))
      .filter(filterByCoinfection(this.coinfections))
      .filter(filterByForms(this.filterForms))
      .filter(searchByFilter(this.searchValue, ["title", "id"]));
  }

  get filteredListOfOtherCases() {
    return this.listOfOtherCases
      ?.filter(filterByCoinfection(this.coinfections))
      .filter(filterByForms(this.filterForms))
      .filter(searchByFilter(this.searchValue, ["title", "id"]));
  }

  get filteredListOfCasesByCurrentPage() {
    return this.filteredListOfCases?.filter(
      filterByCurrentPage(this.currentPage, this.cardsPerPage)
    );
  }

  get isFilteredCasesListNotEmpty() {
    return this.filteredListOfCases && this.filteredListOfCases.length !== 0;
  }

  setCurrentPage = (value: number) => {
    this.currentPage = value;
  };

  get pageCount() {
    return Math.ceil(
      this.filteredListOfCases && this.isFilteredCasesListNotEmpty
        ? this.filteredListOfCases?.length / this.cardsPerPage
        : 1
    );
  }

  get filteredListOfOtherCasesByCurrentPage() {
    return this.filteredListOfOtherCases?.filter(
      filterByCurrentPage(this.currentPage, this.otherCardsPerPage)
    );
  }

  get isFilteredOtherCasesListNotEmpty() {
    return (
      this.filteredListOfOtherCases &&
      this.filteredListOfOtherCases.length !== 0
    );
  }

  get otherPageCount() {
    return Math.ceil(
      this.filteredListOfOtherCases && this.isFilteredOtherCasesListNotEmpty
        ? this.filteredListOfOtherCases?.length / this.otherCardsPerPage
        : 1
    );
  }

  resetCurrentPage = () => (this.currentPage = 1);
}
