import { FC, useState } from 'react';
import { AttachmentsProps } from "./Attachments.helper";
import { Grid } from "@mui/material";
import { Label } from "../../HcvViewCase.styled";
import { ReactComponent as ZoomIcon } from '../../../../../../../../../assets/icon/zoom.svg';
import dummyPDF from './../../../../../../../../../assets/icon/dummyPdf.svg'
import { ArrowLeft, ArrowRight, Caption, ImageContainer } from './Attachments.styled';
import theme from '../../../../../../../../../theme/theme';
import { Modal } from '../../../../../../../../shared/modal/Modal';
import { ImageMain } from '../../../../../../../../shared/modal/lightbox/Lightbox.styled';
import { openPdf } from '../../../../../../../../../utils/openPdf';
import { useModal } from '../../../../../../../../shared/modal/useModal.vm';

export const Attachments: FC<AttachmentsProps> = ({attachments, title = 'ZAŁĄCZNIKI'}) => {
  const [imageSelected, setImageSelected] = useState<number | null>(null);

  const {handleModal, isModalOpened, closeModal} = useModal();

  const handleImageClick = (index: number, fileLink: string) => {
    const isPdf = fileLink.slice(-3) === 'pdf'
    if (isPdf) {
      openPdf(fileLink)
    } else {
      handleModal();
    }
    setImageSelected(index)
  }

  const handleCloseModal = () => {
    closeModal();
    setImageSelected(null);
  }

  return (
    <Grid item xs={12}>
      <Label $mb={2} $size={'small'}>
        {title}
      </Label>

      <Grid container display={'flex'} gap={2}>
        {
          attachments.map((attachment, index) =>
            <ImageContainer onClick={() => handleImageClick(index, attachment.link)} key={attachment.link} item xs={3}
                            bgcolor={theme.colors.blue4} boxShadow={'0px 4px 4px 0px rgba(0, 0, 0, 0.25)'}
                            borderRadius={'10px'} pt={2} pb={2} pl={3} pr={3}>
              <ZoomIcon/>
              <figure>
                {attachment.name.endsWith('.pdf') ? <img src={dummyPDF} alt=""/> :
                  <img src={attachment.link} alt={attachment.name}/>}
                <figcaption>{attachment.originalName}</figcaption>
              </figure>
            </ImageContainer>
          )
        }
        {isModalOpened && imageSelected !== null &&
          <Modal lightbox={true} clickHandler={() => handleCloseModal()} position={'relative'}>
            <ImageMain src={attachments[imageSelected].link}/>
            {imageSelected !== 0 &&
              <ArrowLeft onClick={() => setImageSelected(prevState => prevState !== null ? prevState - 1 : null)}/>}
            {imageSelected !== (attachments.length - 1) &&
              <ArrowRight onClick={() => setImageSelected(prevState => prevState !== null ? prevState + 1 : null)}/>}
            <Caption>{attachments[imageSelected].originalName}</Caption>
          </Modal>}

      </Grid>
    </Grid>
  )
}