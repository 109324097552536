import * as yup from "yup";
// hooks
import { useFormik } from "formik";
import {
  useCaseSettingsStore,
  useNpsStore,
} from "../../../../../../../../../store/hooks";
// types
import { TNpsStage04 } from "./type";
import {
  EnAreaNames,
  EnFormNames,
} from "../../../../../../../../../store/caseSettingsStore";
import { useParams } from "react-router-dom";
import { useCaseApi } from "../../../../../../../../../apiHooks";
import { useButtonsEffects } from "../../../../hooks/useButtonsEffects";
import { useEffect, useState } from "react";

enum ModifyObservations {
  MC_1 = 6,
  MC_3 = 2,
  MC_6 = 1,
}

const validationSchema = yup.object().shape({
  wasUsed: yup.string().required(),
  drugInitiationDate: yup.string().required(),
  likertPatient4: yup.number().required(),
  likertDoctor4: yup.number().required(),
  assessment4: yup.number().required(),
  tenderJointsCount4: yup.number().required(),
  swollenJointsCount4: yup.number().required(),
  resultScaleOption4: yup.string().required(),
  result4: yup.number().required(),
  degreeOfBack4: yup.number().required(),
  generalAssessment24: yup.number().required(),
  observationIntervals: yup.string().required(),
  observations: yup
    .array()
    .of(
      yup.object().shape({
        likertPatient4followup: yup.number().required(),
        likertDoctor4followup: yup.number().required(),
        assessment4followup: yup.number().required(),
        tenderJointsCount4followup: yup.number().required(),
        swollenJointsCount4followup: yup.number().required(),
        resultScaleOption4followup: yup.string().required(),
        result4followup: yup.number().required(),
        degreeOfBack4followup: yup.number().required(),
        generalAssessment24followup: yup.number().required(),
        pasi4followup: yup.number().nullable(),
        dlqi4followup: yup.number().nullable(),
        bsa4followup: yup.number().nullable(),
      })
    )
    .when("observationIntervals", {
      is: (value: string) => value === "co 1 msc.",
      then: (schema) => schema.min(6).required(),
    })
    .when("observationIntervals", {
      is: (value: string) => value === "co 3 msc.",
      then: (schema) => schema.min(2).required(),
    })
    .when("observationIntervals", {
      is: (value: string) => value === "co 6 msc.",
      then: (schema) => schema.min(1).required(),
    }),
  gks4: yup.string().required(),
  gksType4: yup
    .string()
    .nullable()
    .when("gks4", {
      is: (value: string) => value === "tak",
      then: (schema) => schema.required(),
    }),
  singleGks4: yup
    .string()
    .nullable()
    .when("gksType4", {
      is: (value: string) => value === "iniekcja",
      then: (schema) => schema.required(),
    }),
});

const initialValues: TNpsStage04 = {
  wasUsed: "",
  drugInitiationDate: null,
  likertPatient4: null,
  likertDoctor4: null,
  assessment4: null,
  tenderJointsCount4: null,
  swollenJointsCount4: null,
  resultScaleOption4: "",
  result4: null,
  degreeOfBack4: null,
  generalAssessment24: null,
  observationIntervals: "",
  observations: [
    {
      likertPatient4followup: null,
      likertDoctor4followup: null,
      assessment4followup: null,
      tenderJointsCount4followup: null,
      swollenJointsCount4followup: null,
      resultScaleOption4followup: "",
      result4followup: null,
      degreeOfBack4followup: null,
      generalAssessment24followup: null,
      pasi4followup: null,
      dlqi4followup: null,
      bsa4followup: null,
    },
  ],
  gks4: "",
  singleGks4: "",
  gksType4: "",
};

export const useStage04ViewModel = () => {
  // params and location
  const params = useParams();
  const {area, form, caseId} = params;

  // case settings store
  const caseSettingsStore = useCaseSettingsStore();
  const {currentStage} = caseSettingsStore;

  const [periodMode, setPeriodMode] = useState<ModifyObservations | null>(
    ModifyObservations.MC_6
  );
  const [deleteMode, setDeleteMode] = useState<boolean | null>(null);

  // rzs store
  const npsStore = useNpsStore();
  const {fourthStage, caseStage, thirdStage} = npsStore;

  // define API calls
  const caseApi = useCaseApi();
  const valuesObj =
    caseStage && caseStage >= currentStage!
      ? JSON.parse(JSON.stringify(fourthStage))
      : JSON.parse(JSON.stringify(initialValues));

  const formik = useFormik<TNpsStage04>({
    initialValues: {
      ...valuesObj,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const isValidArea = Object.values(EnAreaNames)?.includes(
        area as EnAreaNames
      );
      const isValidForm = Object.values(EnFormNames)?.includes(
        form as EnFormNames
      );

      if (!area || !form || !isValidArea || !isValidForm) {
        throw new Error("Invalid URL");
      }

      if (!caseId) throw new Error("Case ID is missing");
      if (!currentStage) throw new Error("Current stage is missing");

      await caseApi.updateCase({
        area: area as EnAreaNames,
        form: form as EnFormNames,
        caseId,
        value: values,
        stage: currentStage,
      });
    },
  });

  useEffect(() => {
    if (deleteMode === null) {
      let period: ModifyObservations | null = null;

      switch (formik.values.observationIntervals) {
        case "co 1 msc.":
          period = ModifyObservations.MC_1;
          break;
        case "co 3 msc.":
          period = ModifyObservations.MC_3;
          break;
        case "co 6 msc.":
          period = ModifyObservations.MC_6;
          break;
      }
      setPeriodMode(period);
      if (period) setDeleteMode(formik.values.observations.length > period);
    }
  }, [formik.values, deleteMode]);

  // button logic: previous step, next step, and save draft
  useButtonsEffects(formik.values, formik.submitForm);

  const {setFieldValue, setValues} = formik;

  const setOption = (value: number | string, field: string, index?: number) => {
    if (index !== undefined) {
      setFieldValue(`observations[${index}].${field}`, value);
    } else {
      setFieldValue(field, value);
    }
  };

  const setCombinationOption = (
    value: number | string,
    field: string,
    index?: number,
    idx?: number
  ) => {
    if (index !== undefined && idx !== undefined) {
      setFieldValue(
        `observations[${index}].combinationWithMedicine[${idx}].${field}`,
        value
      );
    }
  };

  const observationObject = {
    likertPatient4followup: null,
    likertDoctor4followup: null,
    assessment4followup: null,
    tenderJointsCount4followup: null,
    swollenJointsCount4followup: null,
    resultScaleOption4followup: "",
    result4followup: null,
    degreeOfBack4followup: null,
    generalAssessment24followup: null,
    pasi4followup: null,
    dlqi4followup: null,
    bsa4followup: null,
  };

  const modifyObservations = (value: string) => {
    switch (value) {
      case "co 1 msc.":
        const additionalObservations = 6 - formik.values.observations.length;

        setPeriodMode(ModifyObservations.MC_1);
        const additionalObjects = Array.from(
          {length: additionalObservations},
          () => JSON.parse(JSON.stringify(observationObject))
        );

        const observations = [
          ...formik.values.observations,
          ...additionalObjects,
        ];
        formik.setFieldValue("observations", [...observations]);
        setDeleteMode(observations.length > ModifyObservations.MC_1);

        return;
      case "co 3 msc.":
        const additionalObservations3 = 2 - formik.values.observations.length;

        setPeriodMode(ModifyObservations.MC_3);
        if (formik.values.observations.length >= 2) {
          const newObservations = [...formik.values.observations.slice(0, 2)];
          formik.setFieldValue("observations", [...newObservations]);
          setDeleteMode(newObservations.length > ModifyObservations.MC_3);
        } else {
          const additionalObjects3 = Array.from(
            {length: additionalObservations3},
            () => JSON.parse(JSON.stringify(observationObject))
          );
          const observations = [
            ...formik.values.observations,
            ...additionalObjects3,
          ];
          formik.setFieldValue("observations", [...observations]);
          setDeleteMode(observations.length > ModifyObservations.MC_3);
        }
        return;
      case "co 6 msc.":
        const additionalObservations6 = 1 - formik.values.observations.length;
        setPeriodMode(ModifyObservations.MC_6);
        if (formik.values.observations.length >= 1) {
          const newObservations = [...formik.values.observations.slice(0, 1)];
          formik.setFieldValue("observations", [...newObservations]);
          setDeleteMode(newObservations.length > ModifyObservations.MC_6);
        } else {
          const additionalObjects6 = Array.from(
            {length: additionalObservations6},
            () => JSON.parse(JSON.stringify(observationObject))
          );
          const observations = [
            ...formik.values.observations,
            ...additionalObjects6,
          ];
          formik.setFieldValue("observations", [...observations]);
          setDeleteMode(observations.length > ModifyObservations.MC_6);
        }
        //formik.validateForm().then();
        return;
    }
  };

  const handleYes = () => {
    setValues({
      ...formik.values,
      drugInitiationDate: thirdStage?.programEntryDate,
      likertPatient4: thirdStage?.likertPatient3,
      likertDoctor4: thirdStage?.likertDoctor3,
      assessment4: thirdStage?.assessment3,
      tenderJointsCount4: thirdStage?.tenderJointsCount3,
      swollenJointsCount4: thirdStage?.swollenJointsCount3,
      resultScaleOption4: thirdStage?.resultScaleOption3,
      result4: thirdStage?.result3,
      degreeOfBack4: thirdStage?.degreeOfBack3,
      generalAssessment24: thirdStage?.generalAssessment23,
      observations:
        formik.values.observations?.length > 0
          ? [
            {
              ...formik.values.observations[0],
              likertPatient4followup:
              thirdStage?.treatmentLines[0]?.likertPatient3followup,
              likertDoctor4followup:
              thirdStage?.treatmentLines[0]?.likertDoctor3followup,
              assessment4followup:
              thirdStage?.treatmentLines[0]?.assessment3followup,
              tenderJointsCount4followup:
              thirdStage?.treatmentLines[0].tenderJointsCount3followup,
              swollenJointsCount4followup:
              thirdStage?.treatmentLines[0].swollenJointsCount3followup,
              resultScaleOption4followup:
              thirdStage?.treatmentLines[0].resultScaleOption3followup,
              result4followup: thirdStage?.treatmentLines[0].result3followup,
              degreeOfBack4followup:
              thirdStage?.treatmentLines[0].degreeOfBack3followup,
              generalAssessment24followup:
              thirdStage?.treatmentLines[0].generalAssessment23followup,
            },
            ...formik.values.observations.slice(1), // Kopiowanie reszty elementów bez zmian
          ]
          : [
            {
              ...formik.values.observations[0],
              likertPatient4followup:
              thirdStage?.treatmentLines[0]?.likertPatient3followup,
              likertDoctor4followup:
              thirdStage?.treatmentLines[0]?.likertDoctor3followup,
              assessment4followup:
              thirdStage?.treatmentLines[0]?.assessment3followup,
              tenderJointsCount4followup:
              thirdStage?.treatmentLines[0].tenderJointsCount3followup,
              swollenJointsCount4followup:
              thirdStage?.treatmentLines[0].swollenJointsCount3followup,
              resultScaleOption4followup:
              thirdStage?.treatmentLines[0].resultScaleOption3followup,
              result4followup: thirdStage?.treatmentLines[0].result3followup,
              degreeOfBack4followup:
              thirdStage?.treatmentLines[0].degreeOfBack3followup,
              generalAssessment24followup:
              thirdStage?.treatmentLines[0].generalAssessment23followup,
            },
          ],
    });
  };

  const handleNo = () => {
    setValues({
      ...formik.values,
      drugInitiationDate: null,
      likertPatient4: null,
      likertDoctor4: null,
      assessment4: null,
      tenderJointsCount4: null,
      swollenJointsCount4: null,
      resultScaleOption4: "",
      result4: null,
      degreeOfBack4: null,
      generalAssessment24: null,
      observations:
        formik.values.observations?.length > 0
          ? [
            {
              ...formik.values.observations[0],
              likertPatient4followup: null,
              likertDoctor4followup: null,
              assessment4followup: null,
              tenderJointsCount4followup: null,
              swollenJointsCount4followup: null,
              resultScaleOption4followup: "",
              result4followup: null,
              degreeOfBack4followup: null,
              generalAssessment24followup: null,
              pasi4followup: null,
              dlqi4followup: null,
              bsa4followup: null,
            },
            ...formik.values.observations.slice(1), // Kopiowanie reszty elementów bez zmian
          ]
          : [],
    });
  };
  const addObservation = () => {
    const observations = [...formik.values.observations, observationObject];
    setFieldValue("observations", [...observations]);

    if (periodMode !== null) setDeleteMode(observations.length > periodMode);
  };

  const deleteObservation = (index: number) => {
    const observations = [
      ...formik.values.observations.filter((el, idx) => idx !== index),
    ];
    setFieldValue("observations", observations);
    if (periodMode !== null) setDeleteMode(observations.length > periodMode);
  };
  return {
    formik,
    setOption,
    modifyObservations,
    setCombinationOption,
    handleYes,
    handleNo,
    addObservation,
    deleteMode,
    deleteObservation,
    setDeleteMode,
  };
};
