import styled from "styled-components";
import theme from "../../../../../../../../../theme/theme";
import { Grid } from "@mui/material";

export const DrugsContainer = styled(Grid)`
  @media print {
    background: red;
  }
`;
export const DrugContainer = styled.div`
  background: ${theme.colors.blue4};
  padding: 16px 0 24px 0;
  border-radius: 10px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  width: 100%;
`;
