import * as yup from "yup";
// hooks
import { useFormik } from "formik";
import {
  useCaseSettingsStore,
  useLzsrStore,
} from "../../../../../../../../../store/hooks";
// types
import { TLzsRinvoqStage04 } from "./type";
import {
  EnAreaNames,
  EnFormNames,
} from "../../../../../../../../../store/caseSettingsStore";
import { useParams } from "react-router-dom";
import { useCaseApi } from "../../../../../../../../../apiHooks";
import { useButtonsEffects } from "../../../../hooks/useButtonsEffects";
import { useEffect, useState } from "react";

enum ModifyObservations {
  MC_1 = 6,
  MC_3 = 2,
  MC_6 = 1,
}

const createValidationSchema = (lzsCharacter: string) => {
  return yup.object().shape({
    wasUsed: yup.string().required(),
    drugInitiationDate: yup.string().required(),
    criteriaPsarc4: yup.string().nullable(),
    das28Result4: yup
      .string()
      .nullable()
      .when("criteriaPsarc4", {
        is: (value: string) => value === "das 28",
        then: (schema) => schema.required(),
      }),
    result4: yup
      .number()
      .nullable()
      .when("criteriaPsarc4", {
        is: (value: string) => value === "das 28",
        then: (schema) => schema.required(),
      }),
    likertPatient4: yup
      .number()
      .nullable()
      .when("criteriaPsarc4", {
        is: (value: string) => value === "likert",
        then: (schema) => schema.required(),
      }),
    likertDoctor4: yup
      .number()
      .nullable()
      .when("criteriaPsarc4", {
        is: (value: string) => value === "likert",
        then: (schema) => schema.required(),
      }),
    generalAssessment4: yup
      .number()
      .nullable()
      .when("criteriaPsarc4", {
        is: (value: string) => value === "likert",
        then: (schema) => schema.required(),
      }),
    tenderJointsCount4: yup.number().nullable().integer().min(0),
    swollenJointsCount4: yup.number().nullable().integer().min(0),
    resultScaleOption4: yup
      .string()
      .nullable()
      .test(function (value) {
        // Jeśli lzsCharacter to "postać osiowa", wartość musi być stringiem i nie może być pusta
        if (lzsCharacter === "postać osiowa") {
          return value !== null && value !== undefined;
        }
        // W przeciwnym wypadku wartość może być null
        return true;
      }),
    resultScale4: yup
      .number()
      .nullable()
      .test(function (value) {
        // Jeśli lzsCharacter to "postać osiowa", wartość musi być stringiem i nie może być pusta
        if (lzsCharacter === "postać osiowa") {
          return value !== null && value !== undefined;
        }
        // W przeciwnym wypadku wartość może być null
        return true;
      }),
    degreeOfBack4: yup
      .number()
      .nullable()
      .test(function (value) {
        // Jeśli lzsCharacter to "postać osiowa", wartość musi być stringiem i nie może być pusta
        if (lzsCharacter === "postać osiowa") {
          return value !== null && value !== undefined;
        }
        // W przeciwnym wypadku wartość może być null
        return true;
      }),
    generalAssessment24: yup
      .number()
      .nullable()
      .test(function (value) {
        // Jeśli lzsCharacter to "postać osiowa", wartość musi być stringiem i nie może być pusta
        if (lzsCharacter === "postać osiowa") {
          return value !== null && value !== undefined;
        }
        // W przeciwnym wypadku wartość może być null
        return true;
      }),
    pasi4: yup.number().nullable(),
    dlqi4: yup.number().nullable(),
    bsa4: yup.number().nullable(),
    observationIntervals: yup.string(),
    observations: yup.array().of(
      yup.object().shape({
        monotherapy: yup.string().required(),
        combinationWithMedicine: yup
          .array()
          .of(
            yup.object().shape({
              combinationDrugName: yup.string().required(),
              otherCombinationDrug: yup.string().when("combinationDrugName", {
                is: (value: string) => value === "Inne",
                then: (schema) => schema.required(),
              }),
              combinationDose: yup.number().required(),
              combinationUnit: yup.string().required(),
              otherCombinationUnit: yup.string().when("combinationUnit", {
                is: (value: string) => value === "Inna jednostka",
                then: (schema) => schema.required(),
              }),
              combinationDuration: yup.number().required(),
            })
          )
          .when("monotherapy", {
            is: (value: string) => value === "kombinacja",
            then: (schema) => schema.min(1).required(),
          }),
        criteriaPsarc: yup.string().nullable(),
        das28Result: yup
          .string()
          .nullable()
          .when("criteriaPsarc", {
            is: (value: string) => value === "das 28",
            then: (schema) => schema.required(),
          }),
        result: yup
          .number()
          .nullable()
          .when("criteriaPsarc", {
            is: (value: string) => value === "das 28",
            then: (schema) => schema.required(),
          }),
        likertPatient: yup
          .number()
          .nullable()
          .when("criteriaPsarc", {
            is: (value: string) => value === "likert",
            then: (schema) => schema.required(),
          }),
        likertDoctor: yup
          .number()
          .nullable()
          .when("criteriaPsarc", {
            is: (value: string) => value === "likert",
            then: (schema) => schema.required(),
          }),
        generalAssessment: yup
          .number()
          .nullable()
          .when("criteriaPsarc", {
            is: (value: string) => value === "likert",
            then: (schema) => schema.required(),
          }),
        tenderJointsCount: yup.number().nullable().integer().min(0),
        swollenJointsCount: yup.number().nullable().integer().min(0),
        resultScaleOption: yup
          .string()
          .nullable()
          .test(function (value) {
            // Jeśli lzsCharacter to "postać osiowa", wartość musi być stringiem i nie może być pusta
            if (lzsCharacter === "postać osiowa") {
              return value !== null && value !== undefined;
            }
            // W przeciwnym wypadku wartość może być null
            return true;
          }),
        resultScale: yup
          .number()
          .nullable()
          .test(function (value) {
            // Jeśli lzsCharacter to "postać osiowa", wartość musi być stringiem i nie może być pusta
            if (lzsCharacter === "postać osiowa") {
              return value !== null && value !== undefined;
            }
            // W przeciwnym wypadku wartość może być null
            return true;
          }),
        degreeOfBack: yup
          .number()
          .nullable()
          .test(function (value) {
            // Jeśli lzsCharacter to "postać osiowa", wartość musi być stringiem i nie może być pusta
            if (lzsCharacter === "postać osiowa") {
              return value !== null && value !== undefined;
            }
            // W przeciwnym wypadku wartość może być null
            return true;
          }),
        generalAssessment2: yup
          .number()
          .nullable()
          .test(function (value) {
            // Jeśli lzsCharacter to "postać osiowa", wartość musi być stringiem i nie może być pusta
            if (lzsCharacter === "postać osiowa") {
              return value !== null && value !== undefined;
            }
            // W przeciwnym wypadku wartość może być null
            return true;
          }),
        pasi: yup.string().nullable(),
        dlqi: yup.string().nullable(),
        bsa: yup.string().nullable(),
        psoriasisChanges: yup.string().nullable(),
      })
    ),
    gks4: yup.string().required(),
  });
};

const initialValues: TLzsRinvoqStage04 = {
  wasUsed: "",
  drugInitiationDate: null,
  criteriaPsarc4: "",
  das28Result4: "",
  result4: null,
  likertPatient4: null,
  likertDoctor4: null,
  generalAssessment4: null,
  tenderJointsCount4: null,
  swollenJointsCount4: null,
  resultScaleOption4: null,
  resultScale4: null,
  degreeOfBack4: null,
  generalAssessment24: null,
  pasi4: null,
  dlqi4: null,
  bsa4: null,
  observationIntervals: "",
  observations: [
    {
      monotherapy: "",
      combinationWithMedicine: [],
      criteriaPsarc: "",
      das28Result: "",
      result: null,
      likertPatient: null,
      likertDoctor: null,
      generalAssessment: null,
      tenderJointsCount: null,
      swollenJointsCount: null,
      resultScaleOption: null,
      resultScale: null,
      degreeOfBack: null,
      generalAssessment2: null,
      psoriasisChanges: "",
    },
  ],
  gks4: "",
};

export const useStage04ViewModel = () => {
  // params and location
  const params = useParams();
  const { area, form, caseId } = params;

  const [periodMode, setPeriodMode] = useState<ModifyObservations | null>(
    ModifyObservations.MC_1
  );
  const [deleteMode, setDeleteMode] = useState<boolean | null>(null);

  // case settings store
  const caseSettingsStore = useCaseSettingsStore();
  const { currentStage } = caseSettingsStore;

  // lzssStore
  const lzsrStore = useLzsrStore();
  const { fourthStage, caseStage, secondStage, thirdStage } = lzsrStore;

  // define API calls
  const caseApi = useCaseApi();
  const lzsCharacter = secondStage?.lzsCharacter || "postać obwodowa";

  const valuesObj =
    caseStage && caseStage >= currentStage!
      ? JSON.parse(JSON.stringify(fourthStage))
      : JSON.parse(JSON.stringify(initialValues));

  const formik = useFormik<TLzsRinvoqStage04>({
    initialValues: {
      ...valuesObj,
    },
    validationSchema: createValidationSchema(lzsCharacter),
    onSubmit: async (values) => {
      const isValidArea = Object.values(EnAreaNames)?.includes(
        area as EnAreaNames
      );
      const isValidForm = Object.values(EnFormNames)?.includes(
        form as EnFormNames
      );

      if (!area || !form || !isValidArea || !isValidForm) {
        throw new Error("Invalid URL");
      }

      if (!caseId) throw new Error("Case ID is missing");
      if (!currentStage) throw new Error("Current stage is missing");

      await caseApi.updateCase({
        area: area as EnAreaNames,
        form: form as EnFormNames,
        caseId,
        value: values,
        stage: currentStage,
      });
    },
  });

  useEffect(() => {
    if (deleteMode === null) {
      let period: ModifyObservations | null = null;

      switch (formik.values.observationIntervals) {
        case "co 1 msc.":
          period = ModifyObservations.MC_1;
          break;
        case "co 3 msc.":
          period = ModifyObservations.MC_3;
          break;
        case "co 6 msc.":
          period = ModifyObservations.MC_6;
          break;
      }
      setPeriodMode(period);
      if (period) setDeleteMode(formik.values.observations.length > period);
    }
  }, [formik.values, deleteMode]);

  // button logic: previous step, next step, and save draft
  useButtonsEffects(formik.values, formik.submitForm);

  const { setFieldValue } = formik;

  const setOption = (value: number | string, field: string, index?: number) => {
    if (index !== undefined) {
      setFieldValue(`observations[${index}].${field}`, value);
    } else {
      setFieldValue(field, value);
    }
  };

  const setCombinationOption = (
    value: number | string,
    field: string,
    index?: number,
    idx?: number
  ) => {
    if (index !== undefined && idx !== undefined) {
      setFieldValue(
        `observations[${index}].combinationWithMedicine[${idx}].${field}`,
        value
      );
    }
  };

  const observationObject = {
    monotherapy: "",
    combinationWithMedicine: [],
    criteriaPsarc: "",
    das28Result: "",
    result: null,
    likertPatient: null,
    likertDoctor: null,
    generalAssessment: null,
    tenderJointsCount: null,
    swollenJointsCount: null,
    resultScaleOption: null,
    resultScale: null,
    degreeOfBack: null,
    generalAssessment2: null,
    psoriasisChanges: "",
  };

  const modifyObservations = (value: string) => {
    switch (value) {
      case "co 1 msc.":
        const additionalObservations = 6 - formik.values.observations.length;

        setPeriodMode(ModifyObservations.MC_1);
        const additionalObjects = Array.from(
          { length: additionalObservations },
          () => JSON.parse(JSON.stringify(observationObject))
        );

        const observations = [
          ...formik.values.observations,
          ...additionalObjects,
        ];
        formik.setFieldValue("observations", [...observations]);
        setDeleteMode(observations.length > ModifyObservations.MC_1);

        return;
      case "co 3 msc.":
        const additionalObservations3 = 2 - formik.values.observations.length;

        setPeriodMode(ModifyObservations.MC_3);
        if (formik.values.observations.length >= 2) {
          const newObservations = [...formik.values.observations.slice(0, 2)];
          formik.setFieldValue("observations", [...newObservations]);
          setDeleteMode(newObservations.length > ModifyObservations.MC_3);
        } else {
          const additionalObjects3 = Array.from(
            { length: additionalObservations3 },
            () => JSON.parse(JSON.stringify(observationObject))
          );
          const observations = [
            ...formik.values.observations,
            ...additionalObjects3,
          ];
          formik.setFieldValue("observations", [...observations]);
          setDeleteMode(observations.length > ModifyObservations.MC_3);
        }
        return;
      case "co 6 msc.":
        const additionalObservations6 = 1 - formik.values.observations.length;
        setPeriodMode(ModifyObservations.MC_6);
        if (formik.values.observations.length >= 1) {
          const newObservations = [...formik.values.observations.slice(0, 1)];
          formik.setFieldValue("observations", [...newObservations]);
          setDeleteMode(newObservations.length > ModifyObservations.MC_6);
        } else {
          const additionalObjects6 = Array.from(
            { length: additionalObservations6 },
            () => JSON.parse(JSON.stringify(observationObject))
          );
          const observations = [
            ...formik.values.observations,
            ...additionalObjects6,
          ];
          formik.setFieldValue("observations", [...observations]);
          setDeleteMode(observations.length > ModifyObservations.MC_6);
        }
        //formik.validateForm().then();
        return;
    }
  };

  const addCombination = (index: number) => {
    setFieldValue(`observations[${index}].combinationWithMedicine`, [
      {
        combinationDrugName: "",
        otherCombinationDrug: "",
        combinationDose: null,
        combinationUnit: "",
        otherCombinationUnit: "",
        combinationDuration: null,
      },
    ]);
  };

  const deleteCombination = (index: number, idx: number) => {
    const filteredItems = [
      ...formik.values.observations[index].combinationWithMedicine.filter(
        (el, i) => idx !== i
      ),
    ];
    setFieldValue(
      `observations[${index}].combinationWithMedicine`,
      filteredItems
    );
  };

  const addOtherCombination = (index: number) => {
    setFieldValue(`observations[${index}].combinationWithMedicine`, [
      ...formik.values.observations[index].combinationWithMedicine,
      {
        combinationDrugName: "",
        otherCombinationDrug: "",
        combinationDose: null,
        combinationUnit: "",
        otherCombinationUnit: "",
        combinationDuration: null,
      },
    ]);
  };
  const resetForLikert = () => {
    setFieldValue("das28Result4", "");
    setFieldValue("result4", null);
  };
  const resetForDas = () => {
    setFieldValue("likertPatient4", null);
    setFieldValue("likertDoctor4", null);
  };

  const resetForLikertTreatments = (index: number) => {
    setFieldValue(`observations[${index}].das28Result`, "");
    setFieldValue(`observations[${index}].result`, null);
  };
  const resetForDasTreatments = (index: number) => {
    setFieldValue(`observations[${index}].likertPatient`, null);
    setFieldValue(`observations[${index}].likertDoctor`, null);
  };

  const handleNo = () => {
    formik.setValues({
      ...formik.values,
      drugInitiationDate: null,
      criteriaPsarc4: "",
      das28Result4: "",
      result4: null,
      likertPatient4: null,
      likertDoctor4: null,
      generalAssessment4: null,
      tenderJointsCount4: null,
      swollenJointsCount4: null,
      resultScaleOption4: "",
      resultScale4: null,
      degreeOfBack4: null,
      generalAssessment24: null,
      pasi4: null,
      dlqi4: null,
      bsa4: null,
      observations:
        formik.values.observations?.length > 0
          ? [
              {
                ...formik.values.observations[0],
                monotherapy: "",
                criteriaPsarc: "",
                das28Result: "",
                result: null,
                likertPatient: null,
                likertDoctor: null,
                generalAssessment: null,
                tenderJointsCount: null,
                swollenJointsCount: null,
                resultScaleOption: "",
                resultScale: null,
                degreeOfBack: null,
                generalAssessment2: null,
                combinationWithMedicine:
                  formik.values.observations[0]?.combinationWithMedicine
                    .length > 0
                    ? [
                        {
                          combinationDrugName: "",
                          otherCombinationDrug: "",
                          combinationDose: null,
                          combinationUnit: "",
                          otherCombinationUnit: "",
                          combinationDuration: null,
                        },
                        ...formik.values.observations[0]?.combinationWithMedicine.slice(
                          1
                        ),
                      ]
                    : [],
              },
              ...formik.values.observations?.slice(1), // Kopiowanie reszty elementów bez zmian
            ]
          : [],
    });
  };
  const handleYes = () => {
    formik.setValues({
      ...formik.values,
      drugInitiationDate: thirdStage.programEntryDate,
      criteriaPsarc4: thirdStage.criteriaPsarc3,
      das28Result4: thirdStage.das28Result3,
      result4: thirdStage.result3,
      likertPatient4: thirdStage.likertPatient3,
      likertDoctor4: thirdStage.likertDoctor3,
      generalAssessment4: thirdStage.generalAssessment3,
      tenderJointsCount4: thirdStage.tenderJointsCount3,
      swollenJointsCount4: thirdStage.swollenJointsCount3,
      resultScaleOption4: thirdStage.resultScaleOption3,
      resultScale4: thirdStage.resultScale3,
      degreeOfBack4: thirdStage.degreeOfBack3,
      generalAssessment24: thirdStage.generalAssessment23,
      pasi4: thirdStage.pasi,
      dlqi4: thirdStage.dlqi,
      bsa4: thirdStage.bsa,
      observations:
        thirdStage.treatmentLines?.length > 0
          ? [
              {
                ...formik.values.observations[0],
                monotherapy: thirdStage.treatmentLines[0].monotherapy,
                criteriaPsarc:
                  thirdStage.treatmentLines[0].criteriaPsarc3followup,
                das28Result: thirdStage.treatmentLines[0].das28Result3followup,
                result: thirdStage.treatmentLines[0].result3followup,
                likertPatient:
                  thirdStage.treatmentLines[0].likertPatient3followup,
                likertDoctor:
                  thirdStage.treatmentLines[0].likertDoctor3followup,
                generalAssessment:
                  thirdStage.treatmentLines[0].generalAssessment3followup,
                tenderJointsCount:
                  thirdStage.treatmentLines[0].tenderJointsCount3followup,
                swollenJointsCount:
                  thirdStage.treatmentLines[0].swollenJointsCount3followup,
                resultScaleOption:
                  thirdStage.treatmentLines[0].resultScaleOption3followup,
                resultScale: thirdStage.treatmentLines[0].resultScale3followup,
                degreeOfBack:
                  thirdStage.treatmentLines[0].degreeOfBack3followup,
                generalAssessment2:
                  thirdStage.treatmentLines[0].generalAssessment23followup,

                combinationWithMedicine:
                  thirdStage.treatmentLines[0]?.combinationWithMedicine
                    ?.length > 0
                    ? [
                        {
                          ...thirdStage.treatmentLines[0]
                            ?.combinationWithMedicine[0],
                        },
                        ...(formik.values.observations[0]?.combinationWithMedicine?.slice(
                          1
                        ) || []),
                      ]
                    : [],
              },
              ...formik.values.observations?.slice(1), // Kopiowanie reszty elementów bez zmian
            ]
          : [
              {
                ...formik.values.observations[0],
                monotherapy: thirdStage.treatmentLines[0].monotherapy,
                criteriaPsarc:
                  thirdStage.treatmentLines[0].criteriaPsarc3followup,
                das28Result: thirdStage.treatmentLines[0].das28Result3followup,
                result: thirdStage.treatmentLines[0].result3followup,
                likertPatient:
                  thirdStage.treatmentLines[0].likertPatient3followup,
                likertDoctor:
                  thirdStage.treatmentLines[0].likertDoctor3followup,
                generalAssessment:
                  thirdStage.treatmentLines[0].generalAssessment3followup,
                tenderJointsCount:
                  thirdStage.treatmentLines[0].tenderJointsCount3followup,
                swollenJointsCount:
                  thirdStage.treatmentLines[0].swollenJointsCount3followup,
                resultScaleOption:
                  thirdStage.treatmentLines[0].resultScaleOption3followup,
                resultScale: thirdStage.treatmentLines[0].resultScale3followup,
                degreeOfBack:
                  thirdStage.treatmentLines[0].degreeOfBack3followup,
                generalAssessment2:
                  thirdStage.treatmentLines[0].generalAssessment23followup,

                combinationWithMedicine:
                  thirdStage.treatmentLines[0]?.combinationWithMedicine
                    ?.length > 0
                    ? [
                        {
                          ...thirdStage.treatmentLines[0]
                            ?.combinationWithMedicine[0],
                        },
                        ...(formik.values.observations[0]?.combinationWithMedicine?.slice(
                          1
                        ) || []),
                      ]
                    : [],
              },
            ],
    });
  };

  const addObservation = () => {
    const observations = [...formik.values.observations, observationObject];
    setFieldValue("observations", [...observations]);
    if (periodMode !== null) setDeleteMode(observations.length > periodMode);
  };

  const deleteObservation = (index: number) => {
    const observations = [
      ...formik.values.observations.filter((el, idx) => idx !== index),
    ];
    setFieldValue("observations", observations);
    if (periodMode !== null) setDeleteMode(observations.length > periodMode);
  };

  return {
    formik,
    setOption,
    modifyObservations,
    addCombination,
    deleteCombination,
    addOtherCombination,
    setCombinationOption,
    lzsCharacter,
    resetForDas,
    resetForLikert,
    resetForLikertTreatments,
    resetForDasTreatments,
    handleNo,
    handleYes,
    addObservation,
    deleteMode,
    deleteObservation,
    setDeleteMode,
  };
};
