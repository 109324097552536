import styled from "styled-components";
import theme from "../../../../../../../../../theme/theme";

export const ButtonAddDrug = styled.button`
  border: none;
  height: 32px;
  background-color: transparent;
  padding: 0;
  text-transform: uppercase;
  color: ${theme.colors.abbvie};
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  letter-spacing: 2.4px;

  svg {
    path: {
      fill: ${theme.colors.abbvie};
    }
  }
`;
