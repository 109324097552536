// hooks
import { useCallback } from "react";
import { useAuthStore, useCaseSettingsStore } from "../store/hooks";
import { useApiCall } from "./useApiCall";
import { useParams } from "react-router-dom";

// types and enums
import { IUserLoginResponse } from "../interfaces/admin/user/userLoginResponse";
import { EnAreaNames, EnAreaTitles, EnFormNames, EnFormTitles, TArea, TForm, } from "../store/caseSettingsStore";

export const useAuthApi = () => {
  const {api} = useApiCall();

  const authStore = useAuthStore();
  const caseSettingsStore = useCaseSettingsStore();
  const params = useParams();
  const {token} = params;

  const loginUser = useCallback(
    async (username: string, password: string, pwz: number) => {
      const res: IUserLoginResponse = await api.apiInstance.post(
        "login_check",
        {
          pwz,
          username,
          password,
        },
        true
      );

      if (res) {
        authStore.appLogin(res);
        return res;
      }
      return false;
    },
    [api.apiInstance, authStore]
  );

  const checkAuth = useCallback(async () => {
    const res: IUserLoginResponse = await api.apiInstance.get("is-auth");
    if (res?.role) {
      authStore.appLogin(res);
      // parse areas from response
      // function is used to map areas and forms from api response
      // mabe in future it will be better to move this function to separate file
      // we need to change response from BE or always add rules for parsing areas and forms
      const prescribedAreas: TArea[] = res.areas.map((area) => {
        let areaName: string = "";
        let areaTitle: string = "";
        let areaId: number = 0;
        let forms: TForm[] = [];

        for (let key in area) {
          areaName = area[key].name;
          areaTitle = area[key].title;
          areaId = area[key].id;

          // TODO: delete this function after BE will return correct data
          const setSteps = (formName: EnFormNames) => {
            switch (formName) {
              case EnFormNames.hcv:
                return 4;
              case EnFormNames.rzs:
                return 5;
              case EnFormNames.lzss:
                return 5;
              case EnFormNames.lzsr:
                return 5;
              case EnFormNames.zzsk:
                return 5;
              case EnFormNames.nps:
                return 5;
              default:
                return 5;
            }
          };

          forms = area[key].forms.map((form: any) => {
            const id = form.id;
            const stages = setSteps(form.name);
            const name = form.name;
            const title = form.title;

            return {
              id,
              name: name as EnFormNames,
              title: title as EnFormTitles,
              stages,
            } as TForm;
          });
        }

        return {
          name: areaName as EnAreaNames,
          title: areaTitle as EnAreaTitles,
          id: areaId,
          forms,
        };
      });
      caseSettingsStore.setUserPrescribedAreas(prescribedAreas);
    } else {
      authStore.isSessionExpired = true;
    }
    authStore.isUserLoad = true;
  }, [api.apiInstance, authStore, caseSettingsStore]);

  const serverLogoutUser = useCallback(async () => {
    const res = await api.apiInstance.post("logout", {});
    if (res) {
      await checkAuth();
      return true;
    }
  }, [checkAuth, api.apiInstance]);

  const registerPublishUser = useCallback(
    async (token?: string, data?: any) => {
      const res = await api.apiInstance.post(
        `registration/publishing/${token}`,
        data
      );
      if (res) {
        return true;
      }
    },
    [api.apiInstance]
  );

  const registerViewingUser = useCallback(
    async (data?: any) => {
      const res = await api.apiInstance.post("registration/participant", data);
      if (res) {
        return true;
      }
    },
    [api.apiInstance]
  );

  const register = useCallback(
    async (password: string) => {
      const res = await api.apiInstance.post(
        `registration/${token}`,
        {password},
        true
      );
      if (res) {
        return true;
      }
    },
    [api.apiInstance, token]
  );

  const changePassword = useCallback(
    async (password: string) => {
      const res = await api.apiInstance.post(
        `reset-password/${token}`,
        {password},
        true
      );
      if (res) {
        return true;
      }
    },
    [api.apiInstance, token]
  );

  const resetPassword = useCallback(
    async (email: string) => {
      const res = await api.apiInstance.post("reset-password", {email}, true);
      if (res) {
        return true;
      }
    },
    [api.apiInstance]
  );

  const verifyToken = useCallback(
    async (token: string) => {
      const res = await api.apiInstance.get(
        `registration/verify/${token}`,
        true
      );
      if (res) {
        authStore.emailInfoVerify(res);
      }
    },
    [api.apiInstance, authStore]
  );

  return {
    checkAuth,
    loginUser,
    serverLogoutUser,
    register,
    registerPublishUser,
    registerViewingUser,
    resetPassword,
    changePassword,
    verifyToken,
  };
};
