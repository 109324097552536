import {
  FooterElement,
  FooterElements,
  FooterStyled,
  Separator,
  Container
} from "./Footer.styled";

export const Footer = () => {
  return (
    <FooterStyled>
      <Container>
        <FooterElements>
          <FooterElement>
            <a
              href={require("./../../../../../../src/files/policy.pdf")}
              target={"_blank"}
              rel="noreferrer"
            >
              Polityka prywatności
            </a>
          </FooterElement>
          <Separator>|</Separator>
          <FooterElement>
            <a
              href={require("./../../../../../../src/files/rules.pdf")}
              target={"_blank"}
              rel="noreferrer"
            >
              Regulamin serwisu
            </a>
          </FooterElement>
          <Separator>|</Separator>
          <FooterElement>
            <a
              href={"https://alertpolska.pl/"}
              target={"_blank"}
              rel="noreferrer"
            >
              Zgłoś działanie niepożądane
            </a>
          </FooterElement>
        </FooterElements>
        <FooterElements>
          <a href={'tel:+48601227095'} target={'_blank'} rel='noreferrer'
             style={{textDecoration: 'none', color: "white", fontSize: '10px'}}>Pomoc
            techniczna: 601-227-095</a>
        </FooterElements>
      </Container>
    </FooterStyled>
  );
};
