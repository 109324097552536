// hooks
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCaseSettingsStore } from "../../../../../store/hooks";
import {
  EnAreaTitles,
  EnFormTitles,
  TForm,
} from "../../../../../store/caseSettingsStore";

export const useSetAreaAndFormViewModel = () => {
  const navigate = useNavigate();
  const caseSettingsStore = useCaseSettingsStore();
  const {userPrescribedAreas} = caseSettingsStore;

  const [areaOptions, setAreaOptions] = useState<{ id: number; title: EnAreaTitles }[] | null>(null);
  const [formOptions, setFormOptions] = useState<| {
    id: number;
    title: EnFormTitles;
  }[]
    | null>(null);

  const [currentAreaId, setCurrentAreaId] = useState<number | null>(null);
  const [currentFormId, setCurrentFormId] = useState<number | null>(null);
  const [isAreaError, setAreaError] = useState<boolean>(false);
  const [isFormError, setFormError] = useState<boolean>(false);

  // this useEffect is used to navigate to the add case page if there is only one area and one form
  // for checking if user set link to address bar

  useEffect(() => {
    // TODO: if user did not have any prescribed areas or forms, what we must do?
    if (!userPrescribedAreas) {
      navigate("/");
      return;
    }

    const isOneAreaAndOneForm =
      userPrescribedAreas?.length === 1 &&
      userPrescribedAreas[0].forms.length === 1;

    if (isOneAreaAndOneForm)
      navigate(
        `/case/add/${userPrescribedAreas[0].name}/${userPrescribedAreas[0].forms[0].name}`
      );

    if (userPrescribedAreas.length === 1) {
      setCurrentAreaId(userPrescribedAreas[0].id);
    } else {
      setCurrentAreaId(null);
    }

    setAreaOptions(
      userPrescribedAreas.map((area) => ({id: area.id, title: area.title}))
    );
  }, [userPrescribedAreas, navigate]);

  useEffect(() => {
    if (!currentAreaId) return;
    const tForms: TForm[] =
      userPrescribedAreas?.find((area) => area.id === currentAreaId)?.forms ||
      [];

    if (tForms.length === 1) {
      setCurrentFormId(tForms[0].id);
    } else {
      setCurrentFormId(null);
    }

    setFormOptions(tForms.map((form) => ({id: form.id, title: form.title})));
  }, [currentAreaId, userPrescribedAreas]);

  const homePageRedirectHandler = () => {
    navigate("/");
  };

  const newCaseRedirectHandler = () => {
    const areaName = userPrescribedAreas?.find(
      (area) => area.id === currentAreaId
    )?.name;

    const formName = userPrescribedAreas
      ?.find((area) => area.id === currentAreaId)
      ?.forms.find((form) => form.id === currentFormId)?.name;

    if (areaName && formName) {
      const redirectString = `/case/add/${areaName}/${formName}`;
      navigate(redirectString);
    } else {
      if (!areaName) {
        setAreaError(true)
      }
      if (!formName) {
        setFormError(true)
      }
    }
  };

  return {
    areaOptions,
    formOptions,
    currentAreaId,
    currentFormId,
    setCurrentAreaId,
    setCurrentFormId,
    homePageRedirectHandler,
    newCaseRedirectHandler,
    isAreaError, setAreaError,
    isFormError, setFormError
  };
};
