import { observer } from "mobx-react-lite";

// types
import {
  EnAreaNames,
  EnFormNames,
} from "../../../../../../../store/caseSettingsStore";

// hooks
import { useHcvStore } from "../../../../../../../store/hooks";

// components
import { TopViewCase } from "../../topViewCase/TopViewCase";
import { PatientSection } from "./patientSection/PatientSection";
import { DiagnosisSection } from "./diagnosisSection/DiagnosisSection";
import { QualificationSection } from "./qualificationSection/QualificationSection";
import { TreatmentSection } from "./treatmentSection/TreatmentSection";
import { UserLayout } from "../../../../../../shared/user/layout/UserLayout";
import { useViewCase } from "../../../viewCaseCreator/useViewCase";
import { Button } from "@mui/material";
import html2pdf from "html2pdf.js";

export const HcvViewCase = observer(() => {
  const hcvStore = useHcvStore();
  const { isCase } = useViewCase(
    EnAreaNames.virusology,
    EnFormNames.hcv,
    hcvStore
  );

  async function handleOnClick() {
    const element = document.querySelector("#pdf") as HTMLElement;
    const opt = {
      margin: 1,
      filename: "tytul-opisu-przypadku.pdf",
      jsPDF: { unit: "cm", format: "a4", orientation: "portrait" },
      pagebreak: {
        before: ".breakPage",
      },
    };

    html2pdf().set(opt).from(element).save();
  }

  return (
    <UserLayout>
      <Button onClick={handleOnClick}>Pobierz</Button>
      {isCase && (
        <div id="pdf">
          <TopViewCase caseStore={hcvStore} />
          <PatientSection />
          <DiagnosisSection />
          <QualificationSection />
          <TreatmentSection />
        </div>
      )}
    </UserLayout>
  );
});
