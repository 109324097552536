import {FC, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {observer} from "mobx-react";

// types
import {CaseStatus} from "../../../../../../interfaces/user/draftCase/draft";
// import {
//   EnAreaNames,
//   EnFormNames,
// } from "../../../../../../store/caseSettingsStore";
import {HcvStore} from "../../../../../../store/forms/hcvStore";
import {RzsStore} from "../../../../../../store/forms/rzsStore";
import {LzsrStore} from "../../../../../../store/forms/lzsrStore";
import {LzssStore} from "../../../../../../store/forms/lzssStore";
import {ZzskStore} from "../../../../../../store/forms/zzskStore";
import {NpsStore} from "../../../../../../store/forms/npsStore";

// hooks
//import { useCaseApi } from "../../../../../../apiHooks";
import {useAuthStore} from "../../../../../../store/hooks";

// components
import {Grid} from "@mui/material";
import {Button} from "../../../../../shared/button/Button";
import {WritingComment} from "./writingComment/WritingComment";
import {Comments} from "./comments/Comments";
import {TinyText} from "../virusology/hcv/HcvViewCase.styled";
import {AdminTitle} from "./TopViewCase.styled";

// lib
import {generateLink, UserLink} from "../../../../../../utils";
import {checkPermission} from "../../../../../../utils/checkPermission";
import {RoleFunction} from "../../../../../../constants/projectUserRoles";
import theme from "../../../../../../theme/theme";
import {useCaseApi} from "../../../../../../apiHooks";
import {EnAreaNames, EnFormNames,} from "../../../../../../store/caseSettingsStore";

interface ITopViewCaseProps {
  caseStore: HcvStore | RzsStore | LzsrStore | LzssStore | ZzskStore | NpsStore;
}

export const TopViewCase: FC<ITopViewCaseProps> = observer(({caseStore}) => {
  // params and navigate
  const {caseId, area, form} = useParams();
  const navigate = useNavigate();

  // define API calls
  const {getCasePDF} = useCaseApi();

  // store logic
  const authStore = useAuthStore();
  const {roleFunction, fullName} = authStore;
  const {case: caseElement} = caseStore;

  const [writingCommentVisible, setWritingCommentVisible] = useState(false);

  const casePdfHandler = async () => {
    if (caseId) {
      const res = await getCasePDF(
        +caseId,
        area as EnAreaNames,
        form as EnFormNames
      );
      const blob = new Blob([res], {type: "application/zip"});
      const url = URL.createObjectURL(blob);
      window.open(url);
    }
  };

  const caseEditHandler = () => {
    navigate(
      generateLink(UserLink.EDIT_CASE, [
        [":caseId", String(caseId)],
        [":area", String(area)],
        [":form", String(form)],
      ])
    );
  };

  const isAuthor = caseElement?.createdBy === fullName;

  if (!caseElement) {
    return <></>;
  }

  return (
    <Grid container justifyContent={"space-between"}>
      <Grid item xs={3} display={"flex"} flexDirection={"column"}>
        <TinyText>
          <strong>FORMULARZ:</strong>
          {String(form).toUpperCase()}
        </TinyText>
        <TinyText>
          <strong>ID OPISU PRZYPADKU:</strong>
          {caseElement.customId}
        </TinyText>
        <TinyText>
          <strong>AUTOR:</strong>
          {caseElement.createdBy}
        </TinyText>
        <TinyText>
          <strong>ID PACJENTA:</strong>
          {caseElement.initials?.toUpperCase()}
        </TinyText>
      </Grid>
      <Grid
        item
        xs={7}
        display={"flex"}
        justifyContent={"flex-end"}
        gap={"20px"}
      >
        {(checkPermission(roleFunction, [RoleFunction.ROLE_PARTICIPANT]) || !isAuthor) && (
          <Button
            size={"tiny"}
            text={"Zapytaj"}
            clickHandler={() =>
              setWritingCommentVisible((prevState) => !prevState)
            }
            width={"168px"}
            backgroundColor={theme.colors.white}
            color={theme.colors.abbvie}
          />
        )}
        {checkPermission(roleFunction, [RoleFunction.ROLE_PUBLISHING]) &&
          caseElement.status === CaseStatus.REJECT && (
            <Button
              clickHandler={caseEditHandler}
              size={"tiny"}
              text={"Edytuj"}
              width={"168px"}
              backgroundColor={theme.colors.orange}
              color={"white"}
            />
          )}
        <Button
          clickHandler={casePdfHandler}
          size={"tiny"}
          text={"Pobierz pdf"}
          width={"168px"}
          backgroundColor={theme.colors.white}
          color={theme.colors.abbvie}
        />
      </Grid>
      {isAuthor &&
        caseElement.status === CaseStatus.REJECT &&
        checkPermission(roleFunction, [
          RoleFunction.ROLE_ACCEPTING,
          RoleFunction.ROLE_ADMIN,
          RoleFunction.ROLE_SCIENTIFIC,
          RoleFunction.ROLE_PUBLISHING,
        ]) && (
          <>
            {caseElement.comments && caseElement.comments.length > 0 && (
              <Grid item xs={12} mt={"40px"}>
                <Comments comments={caseElement.comments}/>
              </Grid>
            )}
          </>
        )}
      <Grid item xs={12} mt={"40px"}>
        <AdminTitle>{caseElement.title}</AdminTitle>
      </Grid>
      {writingCommentVisible && (
        <WritingComment
          handleModalClose={() => setWritingCommentVisible(false)}
        />
      )}
    </Grid>
  );
});
