import * as yup from "yup";
// hooks
import { useFormik } from "formik";
import {
  useCaseSettingsStore,
  useZzskStore,
} from "../../../../../../../../../store/hooks";
// types
import { TZzskStage04 } from "./type";
import {
  EnAreaNames,
  EnFormNames,
} from "../../../../../../../../../store/caseSettingsStore";
import { useParams } from "react-router-dom";
import { useCaseApi } from "../../../../../../../../../apiHooks";
import { useButtonsEffects } from "../../../../hooks/useButtonsEffects";
import { useEffect, useState } from "react";

enum ModifyObservations {
  MC_1 = 6,
  MC_3 = 2,
  MC_6 = 1,
}

const validationSchema = yup.object().shape({
  wasUsed: yup.string().required(),
  drugInitiationDate: yup.string().required(),
  resultScaleOption4: yup.string().required(),
  result4: yup.number().required(),
  vasResult4: yup.number().required(),
  obResult4: yup.number().nullable(),
  crpResult4: yup.number().required(),
  observationIntervals: yup.string().required(),
  observations: yup
    .array()
    .of(
      yup.object().shape({
        resultScaleOption: yup.string().required(),
        result: yup.number().required(),
        vasResult: yup.number().required(),
        obResult: yup.number().nullable(),
        crpResult: yup.number().required(),
      })
    )
    .when("observationIntervals", {
      is: (value: string) => value === "co 1 msc.",
      then: (schema) => schema.min(6).required(),
    })
    .when("observationIntervals", {
      is: (value: string) => value === "co 3 msc.",
      then: (schema) => schema.min(2).required(),
    })
    .when("observationIntervals", {
      is: (value: string) => value === "co 6 msc.",
      then: (schema) => schema.min(1).required(),
    }),
  gks4: yup.string().required(),
});

const initialValues: TZzskStage04 = {
  wasUsed: "",
  drugInitiationDate: null,
  resultScaleOption4: "",
  result4: null,
  vasResult4: null,
  obResult4: null,
  crpResult4: null,
  observationIntervals: "",
  observations: [
    {
      resultScaleOption: "",
      result: null,
      vasResult: null,
      obResult: null,
      crpResult: null,
    },
  ],
  gks4: "",
};

export const useStage04ViewModel = () => {
  // params and location
  const params = useParams();
  const {area, form, caseId} = params;

  const [periodMode, setPeriodMode] = useState<ModifyObservations | null>(
    ModifyObservations.MC_1
  );
  const [deleteMode, setDeleteMode] = useState<boolean | null>(null);

  // case settings store
  const caseSettingsStore = useCaseSettingsStore();
  const {currentStage} = caseSettingsStore;

  // rzs store
  const zzskStore = useZzskStore();
  const {fourthStage, caseStage, thirdStage} = zzskStore;

  // define API calls
  const caseApi = useCaseApi();
  const valuesObj =
    caseStage && caseStage >= currentStage!
      ? JSON.parse(JSON.stringify(fourthStage))
      : JSON.parse(JSON.stringify(initialValues));

  const formik = useFormik<TZzskStage04>({
    initialValues: {
      ...valuesObj,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const isValidArea = Object.values(EnAreaNames)?.includes(
        area as EnAreaNames
      );
      const isValidForm = Object.values(EnFormNames)?.includes(
        form as EnFormNames
      );

      if (!area || !form || !isValidArea || !isValidForm) {
        throw new Error("Invalid URL");
      }

      if (!caseId) throw new Error("Case ID is missing");
      if (!currentStage) throw new Error("Current stage is missing");

      await caseApi.updateCase({
        area: area as EnAreaNames,
        form: form as EnFormNames,
        caseId,
        value: values,
        stage: currentStage,
      });
    },
  });

  useEffect(() => {
    if (deleteMode === null) {
      let period: ModifyObservations | null = null;

      switch (formik.values.observationIntervals) {
        case "co 1 msc.":
          period = ModifyObservations.MC_1;
          break;
        case "co 3 msc.":
          period = ModifyObservations.MC_3;
          break;
        case "co 6 msc.":
          period = ModifyObservations.MC_6;
          break;
      }
      setPeriodMode(period);
      if (period) setDeleteMode(formik.values.observations.length > period);
    }
  }, [formik.values, deleteMode]);

  // button logic: previous step, next step, and save draft
  useButtonsEffects(formik.values, formik.submitForm);

  const {setFieldValue, setValues} = formik;

  const setOption = (value: number | string, field: string, index?: number) => {
    if (index !== undefined) {
      setFieldValue(`observations[${index}].${field}`, value);
    } else {
      setFieldValue(field, value);
    }
  };

  const setCombinationOption = (
    value: number | string,
    field: string,
    index?: number,
    idx?: number
  ) => {
    if (index !== undefined && idx !== undefined) {
      setFieldValue(
        `observations[${index}].combinationWithMedicine[${idx}].${field}`,
        value
      );
    }
  };

  const observationObject = {
    resultScaleOption: "",
    result: null,
    vasResult: null,
    obResult: null,
    crpResult: null,
  };
  const modifyObservations = (value: string) => {
    switch (value) {
      case "co 1 msc.":
        const additionalObservations = 6 - formik.values.observations.length;

        setPeriodMode(ModifyObservations.MC_1);
        const additionalObjects = Array.from(
          {length: additionalObservations},
          () => JSON.parse(JSON.stringify(observationObject))
        );

        const observations = [
          ...formik.values.observations,
          ...additionalObjects,
        ];
        formik.setFieldValue("observations", [...observations]);
        setDeleteMode(observations.length > ModifyObservations.MC_1);

        return;
      case "co 3 msc.":
        const additionalObservations3 = 2 - formik.values.observations.length;

        setPeriodMode(ModifyObservations.MC_3);
        if (formik.values.observations.length >= 2) {
          const newObservations = [...formik.values.observations.slice(0, 2)];
          formik.setFieldValue("observations", [...newObservations]);
          setDeleteMode(newObservations.length > ModifyObservations.MC_3);
        } else {
          const additionalObjects3 = Array.from(
            {length: additionalObservations3},
            () => JSON.parse(JSON.stringify(observationObject))
          );
          const observations = [
            ...formik.values.observations,
            ...additionalObjects3,
          ];
          formik.setFieldValue("observations", [...observations]);
          setDeleteMode(observations.length > ModifyObservations.MC_3);
        }
        return;
      case "co 6 msc.":
        const additionalObservations6 = 1 - formik.values.observations.length;
        setPeriodMode(ModifyObservations.MC_6);
        if (formik.values.observations.length >= 1) {
          const newObservations = [...formik.values.observations.slice(0, 1)];
          formik.setFieldValue("observations", [...newObservations]);
          setDeleteMode(newObservations.length > ModifyObservations.MC_6);
        } else {
          const additionalObjects6 = Array.from(
            {length: additionalObservations6},
            () => JSON.parse(JSON.stringify(observationObject))
          );
          const observations = [
            ...formik.values.observations,
            ...additionalObjects6,
          ];
          formik.setFieldValue("observations", [...observations]);
          setDeleteMode(observations.length > ModifyObservations.MC_6);
        }
        //formik.validateForm().then();
        return;
    }
  };

  const handleYes = () => {
    setValues({
      ...formik.values,
      drugInitiationDate: thirdStage.programEntryDate,
      resultScaleOption4: thirdStage.resultScaleOption3,
      result4: thirdStage.result3,
      vasResult4: thirdStage.vasResult3,
      obResult4: thirdStage.obResult3,
      crpResult4: thirdStage.crpResult3,
      observations:
        formik.values.observations?.length > 0
          ? [
            {
              resultScaleOption:
              thirdStage.treatmentLines[0].resultScaleOption3followup,
              result: thirdStage.treatmentLines[0].result3followup,
              vasResult: thirdStage.treatmentLines[0].vasResult3followup,
              obResult: thirdStage.treatmentLines[0].obResult3followup,
              crpResult: thirdStage.treatmentLines[0].crpResult3followup,
            },
            ...formik.values.observations.slice(1), // Kopiowanie reszty elementów bez zmian
          ]
          : [
            {
              resultScaleOption:
              thirdStage.treatmentLines[0].resultScaleOption3followup,
              result: thirdStage.treatmentLines[0].result3followup,
              vasResult: thirdStage.treatmentLines[0].vasResult3followup,
              obResult: thirdStage.treatmentLines[0].obResult3followup,
              crpResult: thirdStage.treatmentLines[0].crpResult3followup,
            },
          ],
    });
  };

  const handleNo = () => {
    setValues({
      ...formik.values,
      drugInitiationDate: null,
      resultScaleOption4: "",
      result4: null,
      vasResult4: null,
      obResult4: null,
      crpResult4: null,
      observations:
        formik.values.observations?.length > 0
          ? [
            {
              resultScaleOption: "",
              result: null,
              vasResult: null,
              obResult: null,
              crpResult: null,
            },
            ...formik.values.observations.slice(1), // Kopiowanie reszty elementów bez zmian
          ]
          : [],
    });
  };
  const addObservation = () => {
    const observations = [...formik.values.observations, observationObject];
    setFieldValue("observations", [...observations]);
    if (periodMode !== null) setDeleteMode(observations.length > periodMode);
  };

  const deleteObservation = (index: number) => {
    const observations = [
      ...formik.values.observations.filter((el, idx) => idx !== index),
    ];
    setFieldValue("observations", observations);
    if (periodMode !== null) setDeleteMode(observations.length > periodMode);
  };
  return {
    formik,
    setOption,
    modifyObservations,
    setCombinationOption,
    handleYes,
    handleNo,
    addObservation,
    deleteMode,
    deleteObservation,
    setDeleteMode,
  };
};
